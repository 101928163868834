@import (css)
  url('https://fonts.googleapis.com/css?family=Playfair+Display:400,700,900|Roboto:100,300,400,500,700,900');

/* ==========================================================================
   HTML5 Boilerplate styles - h5bp.com (generated via initializr.com)
   ========================================================================== */
html,
button,
input,
select,
textarea,
body {
  color: #000;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
b,
strong {
  font-weight: 700;
}

a {
  text-decoration: none;
}
/*a:active { color: #333; }*/
a,
a:focus {
  outline: none;
}
body {
  font-size: 1em;
  line-height: 1.4;
  box-sizing: border-box;
  font-family: @font;
  &.lock {
    height: 0;
    .oh;
    .pf;
    z-index: -1;
  }
}
::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}
::selection {
  background: #b3d4fc;
  text-shadow: none;
}
::-webkit-input-placeholder {
  color: #ccc;
  opacity: 1 !important;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #ccc;
  opacity: 1 !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #ccc;
  opacity: 1 !important;
}
:-ms-input-placeholder {
  color: #ccc;
  opacity: 1 !important;
}

/* ==========================================================================
   Custom styles
   ========================================================================== */
html,
body {
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

html * {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
} /* http://stackoverflow.com/questions/9009377/ios-dark-gray-box-active-style */
body {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  @media (min-width: 768px) {
    overflow: hidden;
    .oh;
  }
}
@import 'utils.less';
@import 'components.less';

/*** DIV con background come sfondo ***/
.bg-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
}

/*** Variabili ***/
@black: #000000;
@white: #ffffff;
@red: #d3061a;
@green: #238448;
@blueLight: #2fc0de;
@blue: #009bac;
@gray: #908f8f;
@grayDark: #333333;
@grayLight: #f3f3f3;
@gold: #dcaf42;

@font: 'Gill Sans Nova';

@light: 300;
@regular: 500;
@bold: 600;

.videoFull {
  .pf;
  .tac;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  iframe {
    width: 100vw;
    height: 100vh;
    z-index: 101;
  }
  // .skipVideo{ .tuc; font-weight: @bold; font-size: 13px; color: @white; letter-spacing: 0.3em; }
  .skipVideo {
    .pr;
    .dib;
    cursor: pointer;
    z-index: 102;
    letter-spacing: 0.6em;
    bottom: 120px;
    text-decoration: none;
    text-shadow: 0 0 0 transparent;
    width: 150px;
    min-width: 150px;
    border: 3px solid @white;
    padding: 15px 0;
    padding-left: 10px;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 20.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    margin-top: 25px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    &:hover {
      background: @black;
      text-shadow: 0 0 50px #ffffff, 0 0 5px #ffffff;
      border: 3px solid @black;
    }
  }
}

.wrap {
  .dn;
}
.cta {
  .tuc;
  font-weight: @bold;
  font-size: 13px;
  color: @white;
  letter-spacing: 0.3em;
}
h1,
.h1 {
  .tuc;
  font-weight: @bold;
  font-size: 13px;
  color: @white;
  letter-spacing: 0.3em;
}
p {
  font-weight: @light;
  font-style: italic;
  font-size: 21px;
  line-height: 29px;
  letter-spacing: 0.03em;
  color: @white;
  margin: 0 0 25px 0;
}
.vAlign {
  .pa;
  top: 50%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.hAlign {
  .pa;
  left: 50%;
  transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}
.loader {
  .pf;
  width: 100vw;
  height: 100vh;
  .tac;
  top: 0;
  left: 0;
  .pulsate {
    .pr;
    -webkit-animation: pulse_effect 2s linear infinite;
    -moz-animation: pulse_effect 2s linear infinite;
  }
}
.hide {
  opacity: 0;
}
.show {
  opacity: 1;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-out;
  transition-delay: 0.2s;
  -webkit-transition-delay: 0.2s;
}
.noheight {
  width: 0;
  height: 0;
}
.autoheight {
  width: auto;
  height: auto;
}
header {
  .pf;
  .fl;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  #burgerButton {
    .pa;
    left: 5vw;
    top: 40px;
    cursor: pointer;
    width: 15px;
    &.menu_black .line {
      background-color: #000;
    }
    @media (max-width: 767px) {
      top: 20px;
      z-index: 1000;
    }
    .line {
      .cb;
      .db;
      width: 100%;
      height: 7px;
      background: @white;
      margin-bottom: 7px;
    }
    &:hover {
      width: 30px;
      -webkit-transition: all 0.5s ease-out;
      -moz-transition: all 20.5s ease-out;
      -o-transition: all 0.5s ease-out;
      transition: all 0.5s ease-out;
    }
  }
  #logo {
    .pa;
    width: 260px;
    top: 25px;
    right: 3.5vw;
    right: ~'calc(15.5vw - 130px)';
    @media (max-width: 767px) {
      width: auto;
      left: 0;
      text-align: center;
      top: 15px;
      right: 0;
    }
    img {
      width: 100%;
    }
    @media (max-width: 1024px) {
      right: 20px;
      width: 170px;
      left: auto;
    }
  }
  .menuOverlay {
    .dn;
    .pf;
    width: 100vw;
    height: 100vh;
    top: 0;
    z-index: 10000;
    background: black;
    .logo {
      .pf;
      width: 30vw;
      top: 25px;
      left: 0;
      z-index: 1;
      .tac;
      @media (max-width: 1024px) {
        width: 100%;
      }
      img {
        width: 75px;
      }
    }
    .menuClose {
      .pf;
      width: 21px;
      height: 21px;
      background-image: url(../images/close_menu.png);
      z-index: 2;
      background-size: 100% auto;
      cursor: pointer;
      left: 65px;
      top: 40px;
    }
    [class^='menu-main-'] {
      .pf;
      width: 30vw;
      height: 100vh;
      background: black;
      left: 0;
      .menu {
        padding-left: 90px;
        li {
          padding: 30px 0 30px 90px;
          width: 100%;
          a {
            .pr;
            width: 100%;
            color: white;
            font-size: 22px;
            letter-spacing: 0.04em;
            font-weight: 500;
            &:hover {
              text-shadow: 0 0 1px #ffffff, 0 0 5px #ffffff;
            }
          }
          &:hover {
            a {
              &:after {
                .pa;
                top: 50%;
                content: '';
                left: -52px;
                background-image: url(../images/ico-current-menu.png);
                background-size: 100% auto;
                width: 30px;
                height: 30px;
                margin-top: -15px;
              }
            }
          }
        }
        .sub-menu {
          .dn;
          .pf;
          width: 70vw;
          height: 100vh;
          right: 0;
          top: 0;
          padding-top: 35vh;
          z-index: 3;
          &.glorwhiteTxt {
            li {
              a {
                color: black;
                &:hover {
                  text-shadow: 0 0 1px #000000, 0 0 5px #000000;
                }
              }
            }
          }
          li {
            padding: 21px 0 21px 50px;
            width: 100%;
            a {
              width: 100%;
              color: white;
              font-size: 13px;
              .tuc;
              letter-spacing: 0.3em;
              font-weight: 600;
              text-shadow: none;
              &:hover {
                text-decoration: underline;
              }
              &:after {
                .dn;
              }
            }
            &.onlyMobile{
              @media (min-width:768px) {
                display:none;
              }
            }
          }
          .imgProd {
            .pa;
            top: 0;
            width: 50%;
            right: 0;
            height: 100vh;
            .tac;
            img {
              height: 80%;
              margin-top: 10%;
            }
          }
        }
      }
      .first {
        .sub-menu {
          .db;
        }
      }
      @media (min-width: 1024px) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .bg_menu {
      .db;
      .pf;
      width: 70vw;
      height: 100vh;
      right: 0;
      top: 0;
      z-index: 0;
      background-image: url(/wp-content/uploads/2018/04/bg_cantina_menu-1.jpg);
      background-size: cover;
      background-position: center center;
      pointer-events: none;
    }

    .link-social {
      .pf;
      bottom: 65px;
      width: 30vw;
      left: 0;
      padding-left: 160px;
      li {
        margin: 0 20px;
        .dib;
        width: 20px;
        height: 20px;
        a {
          font-size: 0;
          .dib;
          .menu_icon {
            .dib;
            width: 20px;
            height: 20px;
            text-align: center;
            img {
              height: 100%;
            }
          }
        }
      }
    }
    .link-footer {
      .pf;
      bottom: 55px;
      width: 70vw;
      right: 0;
      padding-left: 38px;
      li {
        margin: 0 12px;
        .dib;
        width: 40px;
        height: 40px;
        background: black;
        border-radius: 100%;
        a {
          .dib;
          font-size: 0;
          .menu_icon {
            .dib;
            width: 40px;
            height: 40px;
            .tac;
            img {
              height: 16px;
              margin-top: 12px;
            }
          }
        }
      }
    }
    .credits {
      .pf;
      font-size: 11px;
      color: @white;
      right: 0vh;
      letter-spacing: 0.1em;
      bottom: 30px;
      width: 70vw;
      padding-left: 60px;
      a {
        color: white;
      }
    }
  }
  .breadcrumbs {
    .pa;
    .tuc;
    left: 10vw;
    font-weight: @light;
    color: @white;
    font-size: 11px;
    letter-spacing: 0.3em;
    line-height: 1.5em;
    top: 40px;
    z-index: 999;
    width: 36vw;
    p {
      font-weight: @light;
      color: @white;
      font-size: 11px;
      letter-spacing: 0.3em;
      line-height: 1.5em;
      font-style: normal;
    }
    a {
      color: @white;
    }
    .breadcrumb_last {
      font-weight: @bold;
    }
    @media (max-width: 1024px) {
      display: none;
    }
    &.menu_black {
      color: @black;
      p {
        color: @black;
      }
      a {
        color: @black;
      }
    }
  }
  @media (max-width: 767px) {
    &.menu_white {
      height: 90px;
      background: @black;
    }
    &.menu_black {
      height: 90px;
      background: @white;
    }
    &.menu_transparent {
      height: 90px;
      background: transparent;
    }
  }
}
h1.outOfPage {
  .pa;
  top: -999px;
}
#mainWrap {
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
  @media (min-width: 768px) {
    .pr;
    .oh;
    height: 100vh;
  }
}

// container: listing
.container {
  height: 100vh;
  width: 100vw;
  .oh;
  .pr;
  .read-more--hide {
    background-image: url('../images/ico-minus.png') !important;
  }
  @media (max-width: 1367px) {
    overflow-x: auto;
    .textWrap {
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50% !important;
    }
    .vAlign {
      top: 10%;
    }
    p {
      font-size: 12px;
      line-height: 20px;
      margin: 0 0 15px;
    }
  }
}

section {
  height: auto;
  width: 100vw;
  @media (min-width: 768px) {
    height: 100vh;
  }
  &#container {
    overflow: auto;
    @media (min-width: 768px) {
      .oh;
      .pr;
    }
    .textWrap {
      @media (max-width: 767px) {
        .tac;
        margin: 0 auto;
        width: 90%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    #left {
      .transition;
      .oh;
      .tac;
      z-index: 4;
      background-image: url('../images/bg-home.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      width: 100vw;
      border-radius: 0;
      right: 0;
      left: 0;
      position: relative;
      top: 0;
      height: 100vh;
      background-position: center center;
      @media (min-width: 768px) {
        width: 100vw;
        border-top-right-radius: 13% 29%;
        border-bottom-right-radius: 50% 71%;
        .pa;
        right: 42vw;
        left: auto;
        top: -1.25vh;
        height: 116.5vh;
        background-position: 30vw -14vh;
      }
      @media (max-width: 768px) {
        background-position: left;
      }

      @media (min-width: 768px) {
        &.page_home {
          border-color: #e62a2a;
          border-style: solid;
          border-width: 5px;
        }
      }
      .contenitore {
        h2 {
          .tuc;
          font-weight: @bold;
          font-size: 13px;
          color: @white;
          letter-spacing: 0.3em;
        }
      }
      &:before {
        .pa;
        content: '';
        top: 0;
        height: 100%;
        right: -150%;
        background-image: url('../images/bg-overlay.png');
        background-size: 100% auto;
        width: 300%;
      }
      .bg-cover {
        .contentBox {
          background: #0006;
        }
      }
      &.singlePage {
        background-size: auto 100%;
        background-position: right center;
      }
      &.contatti_page {
        p {
          margin: 0;
        }
        .mappa {
          .pr;
          width: 100%;
          height: 116.5vh;
          top: -1.25vh;
          iframe {
            width: 100%;
            height: 116.5vh;
          }
        }
      }
      .contentBox {
        float: none;
        width: 100%;
        height: 100%;
        top: 1.25vh;
        background-color: rgba(144, 98, 98, 0.2);
        @media (min-width: 768px) {
          .pr;
          .fr;
          width: 58vw;
        }
        .logoCuore {
          width: 47px;
          height: auto;
        }
        .textWrap {
          .tal;
          margin: 0 auto;
          width: 90%;
          @media (min-width: 768px) {
            margin: 0 0 0 5vw;
            width: 35vw;
          }
          &.textCenter {
            .tac;
          }
          &.home {
            @media (min-width: 768px) {
              top: 25%;
            }
          }
          &.hAlign {
            margin: 0;
          }
          .more-content {
            height: 0;
            overflow: hidden;
          }
          .read-more {
            .cb;
            .db;
            cursor: pointer;
            width: 12px;
            height: 12px;
            background-image: url('../images/ico-more.png');
            background-size: 100% auto;
            &.gold {
              background-image: url('../images/ico-more-gold.png');
            }
          }
          a {
            color: @white;
            text-decoration: underline;
          }
          h2 {
            color: @white;
          }
          &.gold h1 {
            color: #f0c236;
          }
        }
        .cta {
          .pa;
          bottom: 20px;
          text-shadow: 0 0 1px #ffffff, 0 0 5px #ffffff;
          &.ctaNext {
            .pr;
            .dib;
            bottom: auto;
            margin-top: 20px;
            text-decoration: none;
            -webkit-transition: all 0.5s ease-in-out;
            -moz-transition: all 0.8s ease-in-out;
            -o-transition: all 0.8s ease-in-out;
            transition: all 0.8s ease-out;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      .payoff {
        .dib;
        font-weight: @bold;
        letter-spacing: 0.03em;
        color: @white;
        font-size: 45px;
        padding-top: 15px;
        line-height: 45px;
        font-style: normal;
      }

      &.page_home {
        right: 30.3vw;
        &:before {
          .dn;
        }
        .contentBox {
          background: transparent;
          width: 70vw;
          .textWrap {
            width: 50vw;
          }

          .cta {
            .pr;
            .dib;
            letter-spacing: 0.6em;
            bottom: auto;
            text-decoration: none;
            text-shadow: 0 0 0 transparent;
            width: 230px;
            min-width: 230px;
            border: 3px solid @white;
            padding: 20px 0;
            -webkit-transition: all 0.5s ease-out;
            -moz-transition: all 20.5s ease-out;
            -o-transition: all 0.5s ease-out;
            transition: all 0.5s ease-out;
            margin-top: 25px;
            background-color: rgba(0, 0, 0, 0.5);
            cursor: pointer;
            z-index: 99;
            &:hover {
              background: @black;
              text-shadow: 0 0 50px #ffffff, 0 0 5px #ffffff;
              border: 3px solid @black;
            }
          }

          @media (max-width: 767px) {
            width: 100vw;
            .textWrap {
              width: 90vw;
            }
          }
        }
      }

      &.fullpage {
        .transition;
        box-sizing: content-box;
        top: -1.25vh;
        right: -27vw;
        width: 102vw;
        height: 116.5vh;
        padding-right: 25vw;
        z-index: 4;
        background-image: url('../images/bg-main-blur.jpg');
        background-position: left center;
        background-size: cover;
        .contentBox {
          width: 102vw;
          .textWrap {
            .dn;
          }
        }

        &:before {
          .dn;
        }
      }

      &.page404 {
        .transition;
        box-sizing: content-box;
        top: -1.25vh;
        right: -27vw;
        width: 102vw;
        height: 116.5vh;
        padding-right: 25vw;
        z-index: 4;
        background-image: url('../images/bg-home.jpg');
        background-position: left center;
        background-size: cover;
        .contentBox {
          width: 102vw;
          .textWrap {
            .dn;
            width: 90vw !important;
          }
          @media (max-width: 767px) {
            .textWrap {
              .dn;
              width: 100vw !important;
              left: 0 !important;
              transform: translate(0%, 0%);
            }
          }
        }
        .error-404 {
          &.not-found {
            .pa;
            display: flex;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            justify-content: center;
            align-items: center;
          }
          .plain {
            &.error-page-wrapper {
              .content-container {
                .pr;
                .tac;
                -webkit-transition: top 0.5s ease-out, opacity 0.5s ease-out;
                transition: top 0.5s ease-out, opacity 0.5s ease-out;
                max-width: 100%;
                margin: auto;
                top: -30px;
                opacity: 0;
                &.in {
                  top: 0px;
                  opacity: 1;
                }
              }
              .head-line {
                -webkit-transition: color 0.2s linear;
                transition: color 0.2s linear;
                font-size: 48px;
                line-height: 60px;
                color: rgba(255, 255, 255, 0.2);
                letter-spacing: -1px;
                margin-bottom: 5px;
              }
              .subheader {
                -webkit-transition: color 0.2s linear;
                transition: color 0.2s linear;
                font-size: 36px;
                line-height: 46px;
                color: #fff;
              }
              hr {
                height: 1px;
                background-color: rgba(255, 255, 255, 0.2);
                border: none;
                width: 90%;
                margin: 35px auto;
              }
              .context {
                -webkit-transition: color 0.2s linear;
                transition: color 0.2s linear;
                font-size: 18px;
                line-height: 27px;
                color: #fff;
                p {
                  margin: 0;
                  font-style: normal;
                  &:nth-child(n + 2) {
                    margin-top: 12px;
                  }
                }
              }
              .buttons-container {
                margin-top: 45px;
                .oh;
                display: inline-block;
                .tac;
                a {
                  -webkit-transition: background-color 0.3s linear,
                    color 0.3s linear;
                  transition: background-color 0.3s linear, color 0.3s linear;
                  font-size: 14px;
                  text-transform: uppercase;
                  text-decoration: none;
                  letter-spacing: 1px;
                  color: #fff;
                  border: 1px solid white;
                  border-radius: 0;
                  padding: 8px 30px 9px;
                  display: inline-block;
                  margin-bottom: 20px;
                  &:hover {
                    background-color: white;
                    color: #000 !important;
                    -webkit-transition: background-color 0.3s linear,
                      color 0.3s linear;
                    transition: background-color 0.3s linear, color 0.3s linear;
                  }
                }
                @media (min-width: 560px) {
                  a:nth-of-type(2) {
                    margin-left: 20px;
                  }
                }
              }
              @media (max-width: 560px) {
                .buttons-container {
                  -webkit-box-orient: vertical;
                  -webkit-box-direction: normal;
                  -ms-flex-direction: column;
                  flex-direction: column;
                  -webkit-box-pack: center;
                  -ms-flex-pack: center;
                  justify-content: center;
                  -webkit-box-align: center;
                  -ms-flex-align: center;
                  align-items: center;
                }
              }
            }
          }
          .ie {
            .plain {
              &.error-page-wrapper {
                .content-container {
                  margin-top: 150px;
                }
              }
            }
          }
        }

        @media (max-width: 768px) {
          .error-404.not-found {
            width: 100%;
          }
        }
        &:before {
          .dn;
        }
      }
      &.corporateTemplate {
        background-size: cover;
        .contentBox {
          background: transparent;
          .textWrap {
            .db;
            width: 50vw;
          }
        }
      }
      .sidebar {
        right: 10vw;
        ul {
          width: 100%;
          li {
            .tar;
            width: 100%;
            padding: 7px 0;
            color: @white;
            a {
              .tuc;
              color: @white;
              font-size: 15px;
              font-weight: @bold;
              letter-spacing: 0.3em;
              &:hover {
                text-decoration: underline;
                -webkit-transition: all 0.5s ease-in-out;
                -moz-transition: all 0.8s ease-in-out;
                -o-transition: all 0.8s ease-in-out;
                transition: all 0.8s ease-out;
              }
            }
            &.current-menu-item {
              .pr;
              &:after {
                .pa;
                top: 2px;
                content: '';
                right: -45px;
                background-image: url('../images/ico-current-menu.png');
                background-size: 100% auto;
                width: 32px;
                height: 32px;
              }
              a {
                text-decoration: underline;
              }
            }
          }
        }
      }
      .fixedContent {
        .pa;
        top: 0;
        bottom: 0;
        .oh;
        width: 100%;
        z-index: 4;
        &:before {
          .pa;
          content: '';
          top: -1.25vh;
          height: 116.5vh;
          right: -150%;
          background-image: url('../images/bg-overlay.png');
          background-size: 100% auto;
          width: 300%;
          z-index: -1;
        }
        .contentBox {
          z-index: 4;
        }
      }
      &.noBg {
        background: red;
        border: 6px solid red;
      }
    }

    #right {
      .pa;
      .oh;
      right: 0;
      width: 100%;
      height: 100vh;
      z-index: 2;
      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.8s ease-in-out;
      -o-transition: all 0.8s ease-in-out;
      transition: all 0.8s ease-out;
      background-position: right center;

      @media (max-width: 767px) {
        height: auto;
        padding: 50px 0;
      }
      .right-static {
        @media (max-width: 767px) {
          position: static;
          transform: none;
        }
      }

      &:after {
        .pa;
        z-index: 2;
        content: '';
        top: -1.25vh;
        right: 0;
        width: 100vw;
        height: 116.5vh;
        background-image: url('../images/bg-home.jpg');
        background-position: 0vw -14vh;
        background-size: cover;
        background-repeat: no-repeat;

        @media (max-width: 767px) {
          display: none;
        }
      }
      &.noBgImage {
        &:after {
          .dn;
        }
      }
      .bg_right_overlay {
        .pa;
        .dn;
        top: 0;
        right: 0;
        height: 100vh;
        width: 100%;
        z-index: 3;
        &.section_glor {
          background-image: url('../images/bg_glor.jpg');
          background-position: right center;
          background-size: auto 100%;
          &:after,
          &:before {
            .dn;
          }
        }
        &.section_prosecco {
          background-image: url('../images/bg_prosecco.jpg');
          background-position: right center;
          background-size: auto 100%;
          &:after,
          &:before {
            .dn;
          }
        }
        &.section_spumante {
          background-image: url('../images/bg_spumante.jpg');
          background-position: right center;
          background-size: auto 100%;
          &:after,
          &:before {
            .dn;
          }
        }
      }
      .row33 {
        .pr;
        .fr;
        .cb; /*height: 33.33vh;*/
        height: 25vh;
        width: 31vw;
        z-index: 4;
        @media (max-width: 767px) {
          height: 100vh;
          width: 100%;
        }
        &:nth-child(1) {
          margin-top: 8vh;
          @media (max-width: 767px) {
            background-image: url('../images/bg_glor.jpg');
            background-position: right center;
            background-size: cover;
            margin-top: -50px;
          }
        }

        &:nth-child(2) {
          @media (max-width: 767px) {
            background-image: url('../images/bg_prosecco.jpg');
            background-position: right center;
            background-size: cover;
          }
        }

        &:nth-child(3) {
          @media (max-width: 767px) {
            background-image: url('../images/bg_spumante.jpg');
            background-position: right center;
            background-size: cover;
            margin-bottom: -50px;
          }
        }

        .cta {
          .tac;
          left: 50%;
          min-width: 202px;
          width: 202px;
          border: 3px solid @white;
          padding: 22px 0;
          -webkit-transition: all 0.5s ease-out;
          -moz-transition: all 20.5s ease-out;
          -o-transition: all 0.5s ease-out;
          transition: all 0.5s ease-out;
          //&:hover{border: 3px solid #333;}
          @media (max-width: 767px) {
            .pa;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background: rgba(0, 0, 0, 0.4);
            letter-spacing: 0.6em;
          }
          @media (max-width: 1440px) {
            min-width: 202px;
            letter-spacing: 0.4em;
          }
          &:hover {
            background: @black;
            text-shadow: 0 0 50px #ffffff, 0 0 5px #ffffff;
            border: 3px solid @grayDark;
          }

          @media (max-width: 768px) {
            width: 150px;
            min-width: 150px;
          }
        }
      }
      &.bg-color {
        &:after,
        &:before {
          .dn;
        }
      }
    }
    .contentDetails {
      width: 90%;
      margin: 0 auto;
      @media (min-width: 768px) {
        width: 29vw;
        right: 5vw;
        margin: 0;
      }
      //h2{ .pr; .tuc; font-size: 13px; letter-spacing: 0.3em; font-weight: @bold; margin-bottom: 25px; color: @white; }
      h2 {
        font-weight: @light;
        font-size: 17px;
        line-height: 23px;
        letter-spacing: 0.03em;
        font-style: normal;
        margin: 0;
      }
      h2:last-of-type {
        margin-bottom: 20px;
      }
      h3 {
        .pr;
        .tuc;
        font-size: 13px;
        letter-spacing: 0.3em;
        font-weight: @bold;
        margin-bottom: 25px;
        color: @white;
      }
      p {
        font-size: 17px;
        line-height: 23px;
        letter-spacing: 0.03em;
        font-style: normal;
      }
      a {
        color: @white;
      }
      .accordion {
        width: 100%;
        .rowAccordion {
          width: 100%;
          h2,
          h3 {
            color: @white;
            cursor: pointer;
            &:after {
              .pa;
              right: 0;
              content: '';
              cursor: pointer;
              width: 12px;
              height: 12px;
              background-image: url('../images/ico-more.png');
              background-size: 100% auto;
            }
            &.close {
              &:after {
                content: '';
                cursor: pointer;
                width: 12px;
                height: 12px;
                background-image: url('../images/ico-minus.png');
                background-size: 100% auto;
              }
            }
          }
          h2 {
            font-weight: 500;
            text-transform: uppercase;
            position: relative;
          }
          .text {
            height: 0;
            overflow: hidden;
            // opacity: 0;
            padding-right: 50px;
          }
        }
      }
      .wpcf7 {
        .dib;
        width: 100%;
        .row {
          .dib;
          width: 100%;
          margin-bottom: 15px;
          font-size: 17px;
          line-height: 23px;
          letter-spacing: 0.03em;
          font-style: @regular;
          color: @white;
          input {
            width: 100%;
            padding: 15px 20px;
            font-size: 16px;
            border: 0;
          }
          input[type='checkbox'] {
            .fl;
            width: 15px;
            height: 15px;
            margin: 0;
            padding: 0;
            margin-top: 0px;
          }
          input[type='submit'] {
            background: @black;
            width: 200px;
            margin-left: ~'calc((100% - 200px) / 2)';
            color: @white;
          }
          textarea {
            width: 100%;
            padding: 10px 20px;
            font-size: 16px;
            border: 0;
          }
        }
      }
    }
    .listing {
      .pa;
      .flex;
      top: 0;
      bottom: 0;
      background-color: @white;
      width: auto;
      .z-30;
      margin-left: 58vw;
      padding: 0 50px;
      // FIX IE
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        width: 100%;
        padding-top: 8%;
      }
      @media (max-width: 767px) {
        margin-left: 100vw;
      }
    }
    .scrollBottom {
      .dn;
      @media (max-width: 767px) {
        .db;
        width: 100%;
        .pa;
        bottom: 0px;
        .tac;
        z-index: 9;
        height: 40px;
      }
    }
    .listing-arrow--forward {
      /*.pa;*/
      .pf;
      bottom: 40px;
      right: 50px;
      .db;
      z-index: 100;
      cursor: pointer;
      &.home-arrow {
        .dib;
        .pr;
        transform: rotate(90deg);
        left: auto;
        right: auto;
        bottom: auto;
        pointer-events: none;
        &.home-arrow-spumante {
          .dn;
        }
      }
      @media (min-width: 768px) {
        position: fixed;
      }
    }
    .listingItem {
      .tac;
      width: 100vw;
      .flex;
      .flex-column;
      .justify-content-center;
      @media (min-width: 768px) {
        width: 50vw;
      }
      @media (min-width: 1024px) {
        width: 25vw;
      }

      // IPad Pro
      @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
        width: 50vw;
      }

      a {
        .pr;
        .tac;
        .listingImg {
          .pr;
          margin: 0 auto;
          width: 60%;
          @media (max-width: 767px) {
            width: 45%;
          }
        }

        h2 {
          .visual-hidden;
          height: 0;
          margin: 0;
          bottom: 0;
          font-size: 12px;
          line-height: 17.5px;
          letter-spacing: 0.3em;
          color: @black;
          .tuc;
          width: 75%;
          .tac;
          margin: 0 auto;
          transition: all 0.35s ease-in-out;
          @media (max-width: 1024px) {
            opacity: 1;
            visibility: visible;
          }
        }
        .bgOverlay {
          .visual-hidden;
          .pa;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          transition: all 0.35s ease-in-out;
          left: 50%;
          transform: translateX(-50%);
          width: 70%;
          margin: auto;
          @media (max-width: 767px) {
            width: 60%;
          }
          @media (max-width: 1024px) {
            opacity: 1;
            visibility: visible;
            transition: none;
          }
        }
        &:hover {
          h2 {
            .visible;
          }
          .bgOverlay {
            .visible;
          }
        }
      }
    }
    #stopScroll {
      width: 1px;
      height: 1px;
    }
  }

  #multiscroll {
    padding-top: 100px;
    .close_list {
      .pr;
      background: url('../images/ico-close.png') center center no-repeat;
      width: 25px;
      height: 25px;
      .dn;
      background-size: 100%;
      margin: 0 auto;
      @media (max-width: 767px) {
        .db;
        margin: 20px 0;
      }
    }
    @media (max-width: 767px) {
      .flex;
      .flex-column;
    }
  }

  .ms-section:not(.active) {
    .dn;
    @media (min-width: 768px) {
      .db;
    }
  }

  .ms-left {
    @media (max-width: 767px) {
      order: 2;
    }
  }

  .ms-right {
    // display: none; /* FIX MOMENTANEO */
    @media (max-width: 767px) {
      order: 1;
    }
  }

  .ms-left,
  .ms-right {
    touch-action: auto !important;
    @media (max-width: 767px) {
      width: 100% !important;
      position: static !important;
      height: auto !important;
    }
  }
  .temporary {
    .pa;
    width: 100%;
    top: 0;
  }

  .ms-tableCell {
    @media (max-width: 768px) {
      height: auto !important;
    }
  }

  .wine-detail-body {
    font-weight: @light;
    overflow-y: auto !important;
    @media (min-width: 768px) {
      overflow-y: hidden !important;
    }
  }

  .right-section {
    @media (min-width: 1024px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .right-section,
  .left-section {
    .fl;
    .pr;
    height: 100vh;
    font-size: 1.125em;
    width: 100%;

    .download-link {
      color: @gold;
      .tuc;
      font-weight: @regular;
      .db;
      font-size: 20px;
      .z-30;
    }

    .download-section.download-desktop {
      display: none;
      @media (min-width: 768px) {
        display: block;
        .fl;
        .pr;
        width: 10vw;
        height: 100vh;
        z-index: 10;
      }

      .download-link {
        transform: rotate(0);
        .pr !important;

        @media (min-width: 768px) {
          font-size: 13px;
          transform: rotate(-90deg);
          .pr !important;
          width: 200%;
          left: -50%;
        }
      }
    }

    .download-section.download-mobile {
      text-align: center;
      display: block;
      @media (min-width: 768px) {
        display: none;
      }
    }

    .wine-detail {
      .fl;
      color: @grayDark;
      padding-top: 30%;
      @media (min-width: 768px) {
        padding-left: 10vw;
        padding-top: 0;
      }
      h1 {
        width: 90%;
        margin: 0 auto 10px;
        font-size: 22px;
        text-transform: none;
        font-weight: @bold;
        color: @grayDark;
        letter-spacing: 0.03em;
        line-height: 1.14em;
        @media (min-width: 768px) {
          margin: 0 0 30px;
          font-size: 35px;
        }
      }
      p {
        color: @grayDark;
      }

      @media (max-width: 767px) {
        padding-top: 35px;
      }

      &.vAlign {
        @media (max-width: 767px) {
          position: static;
          top: auto;
          -webkit-transform: none;
          -ms-transform: none;
          transform: none;
        }
      }
    }

    &.textCenter {
      .tac;
      .wine-detail__bg {
        position: fixed;
        top: 20%;
        bottom: 0;
        right: -5%;
        z-index: -1;
        width: 50%;
        height: auto;
        @media (max-width: 767px) {
          .dn; /* FIX MOMENTANEO */
          position: absolute;
          left: 10%;
          right: auto;
          z-index: -1;
          height: 100%;
          width: auto;
        }
      }
      .wine-detail__img {
        .pr;
        margin-top: 0px;
        height: 60vh;
        width: auto;
        z-index: 3;
      }
    }
  }

  .right-section {
    @media (max-width: 767px) {
      position: static;
      .oh;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

.fixed-btn {
  display: none;
  @media (min-width: 768px) {
    .db;
    .z-10;
    .pf;
    color: @white;
  }
}

.direction-btn {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  font-size: 0;
  text-indent: -999px;
  background-repeat: no-repeat;
  width: 10px;
  height: 38px;
  background-size: 100% auto;
  cursor: pointer;
  z-index: 999;
}

.prev-btn {
  background-image: url(../images/top-arrow.png);
  top: 20px;
}

.next-btn {
  background-image: url(../images/bottom-arrow.png);
  bottom: 20px;
}

section#container {
  .wine-detail {
    .contentDetails {
      .accordion {
        width: 100%;
        .rowAccordion {
          h2,
          h3 {
            width: 50%;
            line-height: 1;
            margin: 0 0 17px;
            color: @grayDark;
            &:after {
              background-image: url('../images/ico-more-black.png');
              background-size: 100% auto;
              width: 12px;
              height: 12px;
            }
            &.close {
              &:after {
                background-image: url('../images/ico-minus-black.png');
                background-size: 100% auto;
                height: 5px;
                width: 12px;
              }
            }

            @media (max-width: 1400px) {
              width: 100%;
            }
          }
        }
        &.oro {
          .rowAccordion {
            width: 100%;
            h2,
            h3 {
              color: #dcaf42 !important;
              margin-bottom: 17px;
              &:after {
                background-image: url('../images/ico-more-gold.png');
                background-size: 100% auto;
                width: 12px;
                height: 12px;
              }
              &.close {
                &:after {
                  background-image: url('../images/ico-minus-gold.png');
                  background-size: 100% auto;
                  height: 5px;
                  width: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
  &.frontPage {
    background-image: url('../images/bg-home.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    #right {
      &:before {
        .pa;
        z-index: 3;
        content: '';
        top: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        @media (max-width: 767px) {
          .dn;
        }
      }
    }
  }
}
span.wpcf7-list-item {
  margin: 0 !important;
}

.glor_normal_text {
  p {
    font-style: normal;
  }
}

.banner {
  .pf;
  .tac;
  left: 0;
  bottom: 0;
  font-size: 0;
  width: 100vw;
  z-index: 1000;
  img {
    .dib;
    max-width: 100%;
  }
  .close {
    .pa;
    top: 0;
    z-index: 2000;
    background: url('../images/ico-close.png') center center no-repeat;
    background-size: 15px 15px;
    width: 25px;
    height: 25px;
    right: 5px;
    background-color: white;
    border-radius: 100%;
    cursor: pointer;
  }
  &.bannerDesktop {
    @media (max-width: 768px) {
      .dn;
    }
    @media (min-width: 1390px) {
      .close {
        right: ~'calc((100vw - 1390px) / 2)';
      }
    }
  }
  &.bannerMobile {
    .dn;
    img {
      width: 100%;
    }
    .close {
      right: 5px;
    }
    @media (max-width: 768px) {
      .db;
    }
  }
}

@keyframes pulse_effect {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }

  8% {
    -webkit-transform: scale(0);
    opacity: 0;
  }

  15% {
    -webkit-transform: scale(0.1);
    opacity: 1;
  }

  30% {
    -webkit-transform: scale(0.5);
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1);
  }
}

@-webkit-keyframes pulse_effect {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }

  8% {
    -webkit-transform: scale(0);
    opacity: 0;
  }

  15% {
    -webkit-transform: scale(0.1);
    opacity: 1;
  }

  30% {
    -webkit-transform: scale(0.5);
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes pulse_effect {
  0% {
    -moz-transform: scale(0);
    opacity: 0;
  }

  8% {
    -moz-transform: scale(0);
    opacity: 0;
  }

  15% {
    -moz-transform: scale(0.1);
    opacity: 1;
  }

  30% {
    -moz-transform: scale(0.5);
    opacity: 1;
  }

  100% {
    opacity: 0;
    -moz-transform: scale(1);
  }
}

/* ==========================================================================
   Media Queries
	 ========================================================================== */

@media (max-width: 1024px) {
  header {
    .menuOverlay {
      .menuClose {
        left: 20px;
        top: 50px;
      }
      // [class^=menu-main-] { width: 100vw; height: 90vh; box-sizing: border-box; overflow-y: scroll; height: ~'calc(85vh - 120px)'; top: 15vh;
      [class^='menu-main-'] {
        width: 100vw;
        height: 100%;
        box-sizing: border-box;
        overflow-y: scroll;
        display: flex;
        align-items: center;
        // .menu { padding-left: 0; padding-top: 0vh; .pa; width: 100%; transform: translateY(-50%); top: 50%;
        .menu {
          padding-left: 0;
          width: 100%;
          li {
            .tac;
            padding: 35px 0;
            a {
              font-size: 25px;
            }
          }
          .sub-menu {
            .dn;
            background-image: none !important;
            background-color: #000 !important;
            position: static;
            padding: 0px;
            right: auto;
            left: 0px;
            width: auto;
            height: auto;
            li {
              padding: 10px 0 15px 0;
              a {
                &:after {
                  .pa;
                  top: 5px;
                  content: '';
                  left: -52px;
                  background-image: url(../images/ico-current-menu.png);
                  background-size: 100% auto;
                  width: 30px;
                  height: 30px;
                }
              }
            }
          }
        }
      }
      .bg_menu {
        background-image: none;
        background-color: #000;
      }

      .link-social {
        bottom: 100px;
        width: 60vw;
        padding: 0;
      }
      .link-footer {
        bottom: 100px;
        width: 40vw;
        text-align: right;
        padding: 0;
      }
      .credits {
        padding: 0 0 15px 0;
        bottom: 0;
        left: 0;
        .oh;
        .tac;
        width: 82%;
        .tac;
        margin: 0 9%;
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  header {
    .menuOverlay {
      [class^='menu-main-'] {
        .menu {
          li {
            padding: 30px 15px 30px 60px;
          }
        }
      }
      .bg_menu {
        background-image: none;
        background-color: #000;
      }

      .link-social {
        padding-left: 130px;
      }
      .link-footer {
        margin: 0 10px;
      }
    }
  }
}

@media (max-width: 767px) {
  header {
    .menuOverlay {
      [class^='menu-main-'] {
        .menu {
          li {
            .tac;
            padding: 20px 0;
            a {
              font-size: 22px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  header {
    .menuOverlay {
      [class^='menu-main-'] {
        .menu {
          li {
            .tac;
            padding: 20px 0;
            a {
              font-size: 22px;
            }
            .sub-menu,
            .sub-menu * {
              pointer-events: none;
            }
            .contentMenu {
              .pf;
              width: 100vw; /*height: 100vh;*/
              background: @black;
              min-height: 100%;
              top: 0;
              z-index: 1000;
              .menuVisible {
                .db;
                padding-left: 0;
                padding-top: 0vh;
                .pa;
                width: 100%;
                transform: translateY(-50%);
                top: 50%;
              }
              .sub-menu,
              .sub-menu * {
                pointer-events: all;
              }
            }
          }
        }
      }
      .submenuClose {
        .pa;
        width: 21px;
        height: 21px;
        background: @black;
        z-index: 1000;
        left: 20px;
        top: 50px;
        background-image: url(../images/submenu-back.png);
      }
    }
  }
}
@media (min-width: 1150px) {
}

.new-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  text-align: center;
  display: none;
  width: 100%;
  z-index: 9;

  img {
    max-width: 1366px;
    width: 100%;
    cursor: pointer;
    position: relative;
  }

  @media (max-width: 1024px) {
    .banner-desktop {
      display: none;
    }
  }

  @media (min-width: 1024px) {
    .banner-mobile {
      display: none;
    }
  }
}

@media(max-width: 766px){
  .banner-unesco-img{
    bottom: 0 !important;
    max-width: 90% !important;
    img{
      bottom: 70px !important;
      top: auto !important;
    }
  }
}

.eyelet * {
  margin: 0;
  font-size: 16px;
  line-height: 23px;
  font-style: normal;
  font-weight: 500;
}

.premi_riconoscimenti {
  margin-bottom: 40px;
  &__header{
    &--image-container{
      min-height: 500px;
      width: 100%;
      background-size: cover;
      background-position: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      position: relative;
    }
    &--title {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      height: 100%;
      width: 40%;
      font-size: 32px;
      line-height: 40px;
      font-weight: 700!important;
      display: flex;
      padding: 0 60px;
      margin: 0;
      color: #fff;
      flex-direction: column;
      justify-content: center;
      background-color: rgba(0,0,0,0.5);
      p{
        margin: 0 !important;
      }
    }    
    &--description {
      font-family: 'Playfair Display', Georgia, Times, "Times New Roman", serif;
      font-size: 18px;
      font-weight: 500;
      line-height: 25px;
      width: auto;
      display: inline-block;
      padding: 0px 60px;
      margin: 0;
      color: #fff;
    }    
  }
  &__body{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1200px;
    max-height: 440px;
    overflow-y: scroll;
    padding: 20px;
    margin: auto;
    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 2px;
      background-color: #F5F5F5;
    }
    
    &::-webkit-scrollbar
    {
      width: 5px;
      background-color: #F5F5F5;
    }
    
    &::-webkit-scrollbar-thumb
    {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: rgb(218, 174, 71);
    }    
    &--single{
      font-family: PT Sans,sans-serif;
      font-size: 30px;
      line-height: 37px;
      font-weight: 600!important;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: calc(50% - 20px);
      min-height: 120px;
      margin-top: 10px;
      background-color: transparent;
      border: 2px solid #F1DFB7;
      border-radius: 10px;
      background-size: 140px;
      background-repeat: no-repeat;
      background-position: 50%;
      cursor: pointer;
      color: #fff;
      &:hover{
        .premi_riconoscimenti__body--single-count{
          opacity: 1;
          display: block;
        }
        .premi_riconoscimenti__body--single-title{
          opacity: 0;
          display: none;
        }
      }
      .premi_riconoscimenti__body--single-count{
        display: none;
        margin: 0;
        opacity: 0;
      }      
      .premi_riconoscimenti__body--single-title{
        display: block;
        margin: 0;
        opacity: 1;
      }      
      @media (max-width: 780px) {
        width: calc(100% - 40px);
      }
    }    
    &.ajaxify{
      display: none;
    }
  }
  &__ajax-container{
    display: none;
    max-width: 1200px;
    padding: 20px;
    margin: auto;
    &.ajaxify{
      display: flex;
      flex-direction: column;
    }
    &--back{
      font-family: PT Sans, sans-serif;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 1.2px;
      font-weight: 600 !important;    
      display: block;
      margin: 20px 0;     
      cursor: pointer;
      color: #fff;
    }
    &--slider{
      position: relative;
      display: block;
      width: 100%;
      max-width: 350px;
      margin: auto;      
      padding-top: 0 !important;
      background-color: transparent !important;
      background-size: 200px;
      background-repeat: no-repeat;
      background-position: center;      
      &-container{
        display: block;
        width: 100%;
        background-color: transparent !important;
      }
      .slick-next{
        position: absolute;
        top: 0px;
        z-index: 9999;
        bottom: 0;
        right: 0;
        margin: auto;
        border: none;
        color: transparent;
        background: transparent url(../img/next-black.png) 50% no-repeat !important;
        background-size: 40px !important;
        width: 40px;
        height: 40px;
        &:before{
          display: none;
        }
      }
      .slick-prev{
        position: absolute;
        top: 0px;
        z-index: 9999;
        left: 0;
        bottom: 0;
        margin: auto;
        border: none;
        color: transparent;
        background: transparent url(../img/prev-black.png) 50% no-repeat !important;
        background-size: 40px !important;
        width: 40px;
        height: 40px;
        &:before{
          display: none;
        }
      }
    }
    &--single{
      display: flex !important;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 200px !important;
      background-color: transparent;
      font-size: 30px;
      line-height: 37px;
      font-weight: 600 !important;        
      color: #fff;
    }
    &-items{
      max-width: 600px;
      max-height: 350px;
      overflow-y: scroll;
      margin: 40px auto;
      padding: 20px;
      &::-webkit-scrollbar-track
      {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 2px;
        background-color: #F5F5F5;
      }
      
      &::-webkit-scrollbar
      {
        width: 5px;
        background-color: #F5F5F5;
      }
      
      &::-webkit-scrollbar-thumb
      {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: rgb(218, 174, 71);
      }      
      &--single{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        font-family: PT Sans, sans-serif;
        font-size: 20px;
        line-height: 24px;
        margin: 20px 0;
        color: #000 !important;
        img{
          width: 40px;
          margin-right: 10px;
        }
      }
    }
  }
  &__lds-dual-ring {
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 999;
    background-color: rgba(255,255,255,0.8);
    &.active{
      display: flex;
    }
  }
  &__lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #a07e27;
    border-color: #a07e27 transparent #a07e27 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
}