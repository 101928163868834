/*** Varie ***/
@imgs:		"../images/";

.cf {
	*zoom: 1;
	&:before, &:after { content: " "; display: table; }
	&:after { clear: both; }
}
.bs(@sizing: border-box) {
	-ms-box-sizing: @sizing;
	-moz-box-sizing: @sizing;
	-webkit-box-sizing: @sizing;
	box-sizing: @sizing;
}


// Layout

.fl() { float: left; }
.fr() { float: right; }
.fn() { float: none; }

.cl() { clear: left; }
.cr() { clear: right; }
.cb() { clear: both; }

.ov() { overflow: visible; }
.oh() { overflow: hidden; }
.oa() { overflow: auto; }

.dn() { display: none; }
.db() { display: block; }
.di() { display: inline; }
.dib() { display: inline-block; *display: inline; zoom: 1; }

.tal() { text-align: left; }
.tar() { text-align: right; }
.tac() { text-align: center; }

.tuc() { text-transform: uppercase; }
.tul() { text-decoration: underline; }

.block() { .db; }
.hidden() { .dn; }

.pr() { position: relative; }
.pa() { position: absolute; }
.pf() { position: fixed; }
.ps() { position: static; }

.tl() { top: 0; left: 0; }
.tr() { top: 0; right: 0; }
.bl() { bottom: 0; left: 0; }
.br() { bottom: 0; right: 0; }
  
.vam() { vertical-align: middle; }
.transition() { -webkit-transition: all .4s ease-in-out; -moz-transition: all .4s ease-in-out; -o-transition: all .4s ease-in-out; transition: all .4s ease-out; }
// Tricks

.reset_mp() { margin: 0; padding: 0; }
.hide_text() { text-indent: -9999px; }
.uppercase() { text-transform: uppercase; }
.letter-spacing(@value) { @_: @value * 1em / 1000; letter-spacing: @_; }	// http://www.justinmarsan.com/blog/researches/2012/01/07/css-letter-spacing/

.size(@w, @h) { width: @w * 1px; height: @h * 1px; }	// Set width and height
.radius(@value) { -webkit-border-radius: @value * 1px; border-radius: @value * 1px; }



/*NEW VARS*/
.z-negative() { z-index: -1; }
.z-10() { z-index: 10; }
.z-20() { z-index: 20; }
.z-30() { z-index: 30; }
.z-40() { z-index: 40; }
.z-50() { z-index: 50; }
.visual-hidden() { opacity: 0; visibility: hidden; }
.visible() { opacity: 1; visibility: visible; }

.flex() { display: -webkit-box; display: -ms-flexbox; display: flex; }

.flex-column() { -webkit-box-orient: vertical; 0-webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; }

.justify-content-center() { -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; }

.justify-content-between() { -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; }

.align-items-center() { -webkit-box-align: center; -ms-flex-align: center; align-items: center; }

.align-items-start() { -webkit-box-align: start; -ms-flex-align: start; align-items: flex-start; }

.align-self-center() { -ms-flex-item-align: center; align-self: center; }