@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,700,900|Roboto:100,300,400,500,700,900');
/* ==========================================================================
   HTML5 Boilerplate styles - h5bp.com (generated via initializr.com)
   ========================================================================== */
html,
button,
input,
select,
textarea,
body {
  color: #000;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
b,
strong {
  font-weight: 700;
}
a {
  text-decoration: none;
}
/*a:active { color: #333; }*/
a,
a:focus {
  outline: none;
}
body {
  font-size: 1em;
  line-height: 1.4;
  box-sizing: border-box;
  font-family: 'Gill Sans Nova';
}
body.lock {
  height: 0;
  overflow: hidden;
  position: fixed;
  z-index: -1;
}
::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}
::selection {
  background: #b3d4fc;
  text-shadow: none;
}
::-webkit-input-placeholder {
  color: #ccc;
  opacity: 1 !important;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #ccc;
  opacity: 1 !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #ccc;
  opacity: 1 !important;
}
:-ms-input-placeholder {
  color: #ccc;
  opacity: 1 !important;
}
/* ==========================================================================
   Custom styles
   ========================================================================== */
html,
body {
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}
html * {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
}
/* http://stackoverflow.com/questions/9009377/ios-dark-gray-box-active-style */
body {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
@media (min-width: 768px) {
  body {
    overflow: hidden;
  }
}
/*** Varie ***/
.cf {
  *zoom: 1;
}
.cf:before,
.cf:after {
  content: " ";
  display: table;
}
.cf:after {
  clear: both;
}
/*NEW VARS*/
/*** Field generico (label + input) ***/
.field-wrap {
  overflow: hidden;
  position: relative;
}
.field-wrap.error .input-wrap {
  border-color: #ff3333;
}
.field-wrap.error .text-wrap {
  border-color: #ff3333;
}
.field-wrap.error .select-wrap {
  border-color: #ff3333;
}
.field-wrap.error .field-checkbox .checkbox-wrap .placeholder {
  border: 1px solid #ff3333;
}
.field-wrap.error .field-radio .radio-wrap .placeholder {
  border: 1px solid #ff3333;
}
.field-wrap.checked .field-radio .radio-wrap .placeholder {
  background: url("../images/radio.png") no-repeat center center;
}
.field-wrap.checked .field-checkbox .checkbox-wrap .placeholder {
  background: url("../images/checkbox.png") no-repeat center center;
}
.field-wrap label,
.field-wrap .label {
  float: left;
  font: 14px/auto Arial;
  color: #666;
  width: 33.33%;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.field-wrap label.empty,
.field-wrap .label.empty {
  min-height: 1px;
}
.field-wrap .input-wrap {
  border: 1px solid transparent;
  height: auto;
  padding: 2px;
  float: left;
  width: 66.66%;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.field-wrap .input-wrap input {
  width: 100%;
  padding: 0;
  border: 0;
  height: 100%;
  display: block;
  background: none;
}
.field-wrap .text-wrap {
  border: 1px solid transparent;
  padding: 2px;
  float: left;
  width: 66.66%;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.field-wrap .text-wrap textarea {
  width: 100%;
  padding: 0;
  border: 0;
  height: 100%;
  resize: none;
}
.field-wrap .select-wrap {
  border: 1px solid transparent;
  height: auto;
  float: left;
  width: 66.66%;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.field-wrap .select-wrap select {
  width: 100%;
  padding: 0;
  border: 0;
  height: 100%;
}
.field-wrap .field-checkbox .checkbox-wrap {
  float: left;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  margin-top: 5px;
}
.field-wrap .field-checkbox .checkbox-wrap:hover {
  cursor: pointer;
}
.field-wrap .field-checkbox .checkbox-wrap input {
  position: absolute;
  top: -200%;
  left: -200%;
}
.field-wrap .field-checkbox .checkbox-wrap .placeholder {
  border: 1px solid transparent;
  display: block;
  height: 16px;
  width: 16px;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.field-wrap .field-checkbox label {
  display: block;
  width: 100%;
  padding-left: 35px;
  float: none;
}
.field-wrap .field-checkbox label:hover {
  cursor: pointer;
}
.field-wrap .field-radio .radio-wrap {
  float: left;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  margin-top: 5px;
}
.field-wrap .field-radio .radio-wrap:hover {
  cursor: pointer;
}
.field-wrap .field-radio .radio-wrap input {
  position: absolute;
  top: -200%;
  left: -200%;
}
.field-wrap .field-radio .radio-wrap .placeholder {
  border: 1px solid #ccc;
  border-radius: 50%;
  display: block;
  height: 16px;
  width: 16px;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.field-wrap .field-radio label {
  display: block;
  width: 100%;
  padding-left: 35px;
  float: none;
}
.field-wrap .field-radio label:hover {
  cursor: pointer;
}
.field-wrap .file-wrap {
  border: 1px solid transparent;
  height: auto;
  width: 100%;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}
.field-wrap .file-wrap input {
  position: absolute;
  top: -100%;
  left: -100%;
}
.field-wrap .file-wrap label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: 2px;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.field-wrap .file-wrap label:hover {
  cursor: pointer;
}
.field-wrap .file-wrap label .button {
  position: absolute;
  right: 0;
  top: 0;
  width: 33.33%;
  background: transparent;
  text-align: center;
  color: white;
}
.field-wrap .info-wrap {
  height: auto;
  padding: 2px;
  float: left;
  width: 66.66%;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.field-wrap .info-wrap span {
  width: 100%;
  padding: 0;
  border: 0;
  height: 100%;
  display: block;
}
/*** Submit wrap ***/
.submit-wrap {
  overflow: hidden;
  margin-top: 10px;
}
.submit-wrap .submit {
  float: right;
}
.submit-wrap .feedback {
  float: left;
}
/*** Feedback ***/
.feedback {
  display: none;
}
.feedback.general {
  display: block;
  border: 1px solid green;
  color: green;
  padding: 2px 5px;
  margin: 5px 0;
  font: 14px Arial;
}
.feedback.error {
  color: red;
}
/*** Dialogs ***/
.dialog h3 {
  background: black;
  color: white;
  margin: 0;
  padding: 10px 30px 10px 10px;
  white-space: nowrap;
}
.dialog .dialog-content {
  padding: 10px;
  background: white;
}
.dialog .dialog-content p {
  margin: 0;
}
.dialog .dialog-content .buttons {
  margin-top: 20px;
  border-top: 1px solid #eee;
  padding-top: 20px;
  overflow: hidden;
}
.dialog .dialog-content .buttons a {
  float: right;
  background: black;
  color: white;
  padding: 5px 10px;
}
.dialog .dialog-content .buttons .action-confirm {
  margin-left: 10px;
}
/*** DIV con background come sfondo ***/
.bg-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
}
/*** Variabili ***/
.videoFull {
  position: fixed;
  text-align: center;
  width: 100vw;
  height: 100vh;
  z-index: 100;
}
.videoFull iframe {
  width: 100vw;
  height: 100vh;
  z-index: 101;
}
.videoFull .skipVideo {
  position: relative;
  display: inline-block;
  *display: inline;
  zoom: 1;
  cursor: pointer;
  z-index: 102;
  letter-spacing: 0.6em;
  bottom: 120px;
  text-decoration: none;
  text-shadow: 0 0 0 transparent;
  width: 150px;
  min-width: 150px;
  border: 3px solid #ffffff;
  padding: 15px 0;
  padding-left: 10px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 20.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  margin-top: 25px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}
.videoFull .skipVideo:hover {
  background: #000000;
  text-shadow: 0 0 50px #ffffff, 0 0 5px #ffffff;
  border: 3px solid #000000;
}
.wrap {
  display: none;
}
.cta {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  color: #ffffff;
  letter-spacing: 0.3em;
}
h1,
.h1 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  color: #ffffff;
  letter-spacing: 0.3em;
}
p {
  font-weight: 300;
  font-style: italic;
  font-size: 21px;
  line-height: 29px;
  letter-spacing: 0.03em;
  color: #ffffff;
  margin: 0 0 25px 0;
}
.vAlign {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.hAlign {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}
.loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  text-align: center;
  top: 0;
  left: 0;
}
.loader .pulsate {
  position: relative;
  -webkit-animation: pulse_effect 2s linear infinite;
  -moz-animation: pulse_effect 2s linear infinite;
}
.hide {
  opacity: 0;
}
.show {
  opacity: 1;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-out;
  transition-delay: 0.2s;
  -webkit-transition-delay: 0.2s;
}
.noheight {
  width: 0;
  height: 0;
}
.autoheight {
  width: auto;
  height: auto;
}
header {
  position: fixed;
  float: left;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
}
header #burgerButton {
  position: absolute;
  left: 5vw;
  top: 40px;
  cursor: pointer;
  width: 15px;
}
header #burgerButton.menu_black .line {
  background-color: #000;
}
@media (max-width: 767px) {
  header #burgerButton {
    top: 20px;
    z-index: 1000;
  }
}
header #burgerButton .line {
  clear: both;
  display: block;
  width: 100%;
  height: 7px;
  background: #ffffff;
  margin-bottom: 7px;
}
header #burgerButton:hover {
  width: 30px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 20.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
header #logo {
  position: absolute;
  width: 260px;
  top: 25px;
  right: 3.5vw;
  right: calc(15.5vw - 130px);
}
@media (max-width: 767px) {
  header #logo {
    width: auto;
    left: 0;
    text-align: center;
    top: 15px;
    right: 0;
  }
}
header #logo img {
  width: 100%;
}
@media (max-width: 1024px) {
  header #logo {
    right: 20px;
    width: 170px;
    left: auto;
  }
}
header .menuOverlay {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 10000;
  background: black;
}
header .menuOverlay .logo {
  position: fixed;
  width: 30vw;
  top: 25px;
  left: 0;
  z-index: 1;
  text-align: center;
}
@media (max-width: 1024px) {
  header .menuOverlay .logo {
    width: 100%;
  }
}
header .menuOverlay .logo img {
  width: 75px;
}
header .menuOverlay .menuClose {
  position: fixed;
  width: 21px;
  height: 21px;
  background-image: url(../images/close_menu.png);
  z-index: 2;
  background-size: 100% auto;
  cursor: pointer;
  left: 65px;
  top: 40px;
}
header .menuOverlay [class^='menu-main-'] {
  position: fixed;
  width: 30vw;
  height: 100vh;
  background: black;
  left: 0;
}
header .menuOverlay [class^='menu-main-'] .menu {
  padding-left: 90px;
}
header .menuOverlay [class^='menu-main-'] .menu li {
  padding: 30px 0 30px 90px;
  width: 100%;
}
header .menuOverlay [class^='menu-main-'] .menu li a {
  position: relative;
  width: 100%;
  color: white;
  font-size: 22px;
  letter-spacing: 0.04em;
  font-weight: 500;
}
header .menuOverlay [class^='menu-main-'] .menu li a:hover {
  text-shadow: 0 0 1px #ffffff, 0 0 5px #ffffff;
}
header .menuOverlay [class^='menu-main-'] .menu li:hover a:after {
  position: absolute;
  top: 50%;
  content: '';
  left: -52px;
  background-image: url(../images/ico-current-menu.png);
  background-size: 100% auto;
  width: 30px;
  height: 30px;
  margin-top: -15px;
}
header .menuOverlay [class^='menu-main-'] .menu .sub-menu {
  display: none;
  position: fixed;
  width: 70vw;
  height: 100vh;
  right: 0;
  top: 0;
  padding-top: 35vh;
  z-index: 3;
}
header .menuOverlay [class^='menu-main-'] .menu .sub-menu.glorwhiteTxt li a {
  color: black;
}
header .menuOverlay [class^='menu-main-'] .menu .sub-menu.glorwhiteTxt li a:hover {
  text-shadow: 0 0 1px #000000, 0 0 5px #000000;
}
header .menuOverlay [class^='menu-main-'] .menu .sub-menu li {
  padding: 21px 0 21px 50px;
  width: 100%;
}
header .menuOverlay [class^='menu-main-'] .menu .sub-menu li a {
  width: 100%;
  color: white;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.3em;
  font-weight: 600;
  text-shadow: none;
}
header .menuOverlay [class^='menu-main-'] .menu .sub-menu li a:hover {
  text-decoration: underline;
}
header .menuOverlay [class^='menu-main-'] .menu .sub-menu li a:after {
  display: none;
}
@media (min-width: 768px) {
  header .menuOverlay [class^='menu-main-'] .menu .sub-menu li.onlyMobile {
    display: none;
  }
}
header .menuOverlay [class^='menu-main-'] .menu .sub-menu .imgProd {
  position: absolute;
  top: 0;
  width: 50%;
  right: 0;
  height: 100vh;
  text-align: center;
}
header .menuOverlay [class^='menu-main-'] .menu .sub-menu .imgProd img {
  height: 80%;
  margin-top: 10%;
}
header .menuOverlay [class^='menu-main-'] .first .sub-menu {
  display: block;
}
@media (min-width: 1024px) {
  header .menuOverlay [class^='menu-main-'] {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
header .menuOverlay .bg_menu {
  display: block;
  position: fixed;
  width: 70vw;
  height: 100vh;
  right: 0;
  top: 0;
  z-index: 0;
  background-image: url(/wp-content/uploads/2018/04/bg_cantina_menu-1.jpg);
  background-size: cover;
  background-position: center center;
  pointer-events: none;
}
header .menuOverlay .link-social {
  position: fixed;
  bottom: 65px;
  width: 30vw;
  left: 0;
  padding-left: 160px;
}
header .menuOverlay .link-social li {
  margin: 0 20px;
  display: inline-block;
  *display: inline;
  zoom: 1;
  width: 20px;
  height: 20px;
}
header .menuOverlay .link-social li a {
  font-size: 0;
  display: inline-block;
  *display: inline;
  zoom: 1;
}
header .menuOverlay .link-social li a .menu_icon {
  display: inline-block;
  *display: inline;
  zoom: 1;
  width: 20px;
  height: 20px;
  text-align: center;
}
header .menuOverlay .link-social li a .menu_icon img {
  height: 100%;
}
header .menuOverlay .link-footer {
  position: fixed;
  bottom: 55px;
  width: 70vw;
  right: 0;
  padding-left: 38px;
}
header .menuOverlay .link-footer li {
  margin: 0 12px;
  display: inline-block;
  *display: inline;
  zoom: 1;
  width: 40px;
  height: 40px;
  background: black;
  border-radius: 100%;
}
header .menuOverlay .link-footer li a {
  display: inline-block;
  *display: inline;
  zoom: 1;
  font-size: 0;
}
header .menuOverlay .link-footer li a .menu_icon {
  display: inline-block;
  *display: inline;
  zoom: 1;
  width: 40px;
  height: 40px;
  text-align: center;
}
header .menuOverlay .link-footer li a .menu_icon img {
  height: 16px;
  margin-top: 12px;
}
header .menuOverlay .credits {
  position: fixed;
  font-size: 11px;
  color: #ffffff;
  right: 0vh;
  letter-spacing: 0.1em;
  bottom: 30px;
  width: 70vw;
  padding-left: 60px;
}
header .menuOverlay .credits a {
  color: white;
}
header .breadcrumbs {
  position: absolute;
  text-transform: uppercase;
  left: 10vw;
  font-weight: 300;
  color: #ffffff;
  font-size: 11px;
  letter-spacing: 0.3em;
  line-height: 1.5em;
  top: 40px;
  z-index: 999;
  width: 36vw;
}
header .breadcrumbs p {
  font-weight: 300;
  color: #ffffff;
  font-size: 11px;
  letter-spacing: 0.3em;
  line-height: 1.5em;
  font-style: normal;
}
header .breadcrumbs a {
  color: #ffffff;
}
header .breadcrumbs .breadcrumb_last {
  font-weight: 600;
}
@media (max-width: 1024px) {
  header .breadcrumbs {
    display: none;
  }
}
header .breadcrumbs.menu_black {
  color: #000000;
}
header .breadcrumbs.menu_black p {
  color: #000000;
}
header .breadcrumbs.menu_black a {
  color: #000000;
}
@media (max-width: 767px) {
  header.menu_white {
    height: 90px;
    background: #000000;
  }
  header.menu_black {
    height: 90px;
    background: #ffffff;
  }
  header.menu_transparent {
    height: 90px;
    background: transparent;
  }
}
h1.outOfPage {
  position: absolute;
  top: -999px;
}
#mainWrap {
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
}
@media (min-width: 768px) {
  #mainWrap {
    position: relative;
    overflow: hidden;
    height: 100vh;
  }
}
.container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
}
.container .read-more--hide {
  background-image: url('../images/ico-minus.png') !important;
}
@media (max-width: 1367px) {
  .container {
    overflow-x: auto;
  }
  .container .textWrap {
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50% !important;
  }
  .container .vAlign {
    top: 10%;
  }
  .container p {
    font-size: 12px;
    line-height: 20px;
    margin: 0 0 15px;
  }
}
section {
  height: auto;
  width: 100vw;
}
@media (min-width: 768px) {
  section {
    height: 100vh;
  }
}
section#container {
  overflow: auto;
}
@media (min-width: 768px) {
  section#container {
    overflow: hidden;
    position: relative;
  }
}
@media (max-width: 767px) {
  section#container .textWrap {
    text-align: center;
    margin: 0 auto;
    width: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
section#container #left {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-out;
  overflow: hidden;
  text-align: center;
  z-index: 4;
  background-image: url('../images/bg-home.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  border-radius: 0;
  right: 0;
  left: 0;
  position: relative;
  top: 0;
  height: 100vh;
  background-position: center center;
}
@media (min-width: 768px) {
  section#container #left {
    width: 100vw;
    border-top-right-radius: 13% 29%;
    border-bottom-right-radius: 50% 71%;
    position: absolute;
    right: 42vw;
    left: auto;
    top: -1.25vh;
    height: 116.5vh;
    background-position: 30vw -14vh;
  }
}
@media (max-width: 768px) {
  section#container #left {
    background-position: left;
  }
}
@media (min-width: 768px) {
  section#container #left.page_home {
    border-color: #e62a2a;
    border-style: solid;
    border-width: 5px;
  }
}
section#container #left .contenitore h2 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  color: #ffffff;
  letter-spacing: 0.3em;
}
section#container #left:before {
  position: absolute;
  content: '';
  top: 0;
  height: 100%;
  right: -150%;
  background-image: url('../images/bg-overlay.png');
  background-size: 100% auto;
  width: 300%;
}
section#container #left .bg-cover .contentBox {
  background: #0006;
}
section#container #left.singlePage {
  background-size: auto 100%;
  background-position: right center;
}
section#container #left.contatti_page p {
  margin: 0;
}
section#container #left.contatti_page .mappa {
  position: relative;
  width: 100%;
  height: 116.5vh;
  top: -1.25vh;
}
section#container #left.contatti_page .mappa iframe {
  width: 100%;
  height: 116.5vh;
}
section#container #left .contentBox {
  float: none;
  width: 100%;
  height: 100%;
  top: 1.25vh;
  background-color: rgba(144, 98, 98, 0.2);
}
@media (min-width: 768px) {
  section#container #left .contentBox {
    position: relative;
    float: right;
    width: 58vw;
  }
}
section#container #left .contentBox .logoCuore {
  width: 47px;
  height: auto;
}
section#container #left .contentBox .textWrap {
  text-align: left;
  margin: 0 auto;
  width: 90%;
}
@media (min-width: 768px) {
  section#container #left .contentBox .textWrap {
    margin: 0 0 0 5vw;
    width: 35vw;
  }
}
section#container #left .contentBox .textWrap.textCenter {
  text-align: center;
}
@media (min-width: 768px) {
  section#container #left .contentBox .textWrap.home {
    top: 25%;
  }
}
section#container #left .contentBox .textWrap.hAlign {
  margin: 0;
}
section#container #left .contentBox .textWrap .more-content {
  height: 0;
  overflow: hidden;
}
section#container #left .contentBox .textWrap .read-more {
  clear: both;
  display: block;
  cursor: pointer;
  width: 12px;
  height: 12px;
  background-image: url('../images/ico-more.png');
  background-size: 100% auto;
}
section#container #left .contentBox .textWrap .read-more.gold {
  background-image: url('../images/ico-more-gold.png');
}
section#container #left .contentBox .textWrap a {
  color: #ffffff;
  text-decoration: underline;
}
section#container #left .contentBox .textWrap h2 {
  color: #ffffff;
}
section#container #left .contentBox .textWrap.gold h1 {
  color: #f0c236;
}
section#container #left .contentBox .cta {
  position: absolute;
  bottom: 20px;
  text-shadow: 0 0 1px #ffffff, 0 0 5px #ffffff;
}
section#container #left .contentBox .cta.ctaNext {
  position: relative;
  display: inline-block;
  *display: inline;
  zoom: 1;
  bottom: auto;
  margin-top: 20px;
  text-decoration: none;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-out;
}
section#container #left .contentBox .cta.ctaNext:hover {
  text-decoration: underline;
}
section#container #left .payoff {
  display: inline-block;
  *display: inline;
  zoom: 1;
  font-weight: 600;
  letter-spacing: 0.03em;
  color: #ffffff;
  font-size: 45px;
  padding-top: 15px;
  line-height: 45px;
  font-style: normal;
}
section#container #left.page_home {
  right: 30.3vw;
}
section#container #left.page_home:before {
  display: none;
}
section#container #left.page_home .contentBox {
  background: transparent;
  width: 70vw;
}
section#container #left.page_home .contentBox .textWrap {
  width: 50vw;
}
section#container #left.page_home .contentBox .cta {
  position: relative;
  display: inline-block;
  *display: inline;
  zoom: 1;
  letter-spacing: 0.6em;
  bottom: auto;
  text-decoration: none;
  text-shadow: 0 0 0 transparent;
  width: 230px;
  min-width: 230px;
  border: 3px solid #ffffff;
  padding: 20px 0;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 20.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  margin-top: 25px;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  z-index: 99;
}
section#container #left.page_home .contentBox .cta:hover {
  background: #000000;
  text-shadow: 0 0 50px #ffffff, 0 0 5px #ffffff;
  border: 3px solid #000000;
}
@media (max-width: 767px) {
  section#container #left.page_home .contentBox {
    width: 100vw;
  }
  section#container #left.page_home .contentBox .textWrap {
    width: 90vw;
  }
}
section#container #left.fullpage {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-out;
  box-sizing: content-box;
  top: -1.25vh;
  right: -27vw;
  width: 102vw;
  height: 116.5vh;
  padding-right: 25vw;
  z-index: 4;
  background-image: url('../images/bg-main-blur.jpg');
  background-position: left center;
  background-size: cover;
}
section#container #left.fullpage .contentBox {
  width: 102vw;
}
section#container #left.fullpage .contentBox .textWrap {
  display: none;
}
section#container #left.fullpage:before {
  display: none;
}
section#container #left.page404 {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-out;
  box-sizing: content-box;
  top: -1.25vh;
  right: -27vw;
  width: 102vw;
  height: 116.5vh;
  padding-right: 25vw;
  z-index: 4;
  background-image: url('../images/bg-home.jpg');
  background-position: left center;
  background-size: cover;
}
section#container #left.page404 .contentBox {
  width: 102vw;
}
section#container #left.page404 .contentBox .textWrap {
  display: none;
  width: 90vw !important;
}
@media (max-width: 767px) {
  section#container #left.page404 .contentBox .textWrap {
    display: none;
    width: 100vw !important;
    left: 0 !important;
    transform: translate(0%, 0%);
  }
}
section#container #left.page404 .error-404.not-found {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  justify-content: center;
  align-items: center;
}
section#container #left.page404 .error-404 .plain.error-page-wrapper .content-container {
  position: relative;
  text-align: center;
  -webkit-transition: top 0.5s ease-out, opacity 0.5s ease-out;
  transition: top 0.5s ease-out, opacity 0.5s ease-out;
  max-width: 100%;
  margin: auto;
  top: -30px;
  opacity: 0;
}
section#container #left.page404 .error-404 .plain.error-page-wrapper .content-container.in {
  top: 0px;
  opacity: 1;
}
section#container #left.page404 .error-404 .plain.error-page-wrapper .head-line {
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
  font-size: 48px;
  line-height: 60px;
  color: rgba(255, 255, 255, 0.2);
  letter-spacing: -1px;
  margin-bottom: 5px;
}
section#container #left.page404 .error-404 .plain.error-page-wrapper .subheader {
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
  font-size: 36px;
  line-height: 46px;
  color: #fff;
}
section#container #left.page404 .error-404 .plain.error-page-wrapper hr {
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  width: 90%;
  margin: 35px auto;
}
section#container #left.page404 .error-404 .plain.error-page-wrapper .context {
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
  font-size: 18px;
  line-height: 27px;
  color: #fff;
}
section#container #left.page404 .error-404 .plain.error-page-wrapper .context p {
  margin: 0;
  font-style: normal;
}
section#container #left.page404 .error-404 .plain.error-page-wrapper .context p:nth-child(n + 2) {
  margin-top: 12px;
}
section#container #left.page404 .error-404 .plain.error-page-wrapper .buttons-container {
  margin-top: 45px;
  overflow: hidden;
  display: inline-block;
  text-align: center;
}
section#container #left.page404 .error-404 .plain.error-page-wrapper .buttons-container a {
  -webkit-transition: background-color 0.3s linear, color 0.3s linear;
  transition: background-color 0.3s linear, color 0.3s linear;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;
  color: #fff;
  border: 1px solid white;
  border-radius: 0;
  padding: 8px 30px 9px;
  display: inline-block;
  margin-bottom: 20px;
}
section#container #left.page404 .error-404 .plain.error-page-wrapper .buttons-container a:hover {
  background-color: white;
  color: #000 !important;
  -webkit-transition: background-color 0.3s linear, color 0.3s linear;
  transition: background-color 0.3s linear, color 0.3s linear;
}
@media (min-width: 560px) {
  section#container #left.page404 .error-404 .plain.error-page-wrapper .buttons-container a:nth-of-type(2) {
    margin-left: 20px;
  }
}
@media (max-width: 560px) {
  section#container #left.page404 .error-404 .plain.error-page-wrapper .buttons-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
section#container #left.page404 .error-404 .ie .plain.error-page-wrapper .content-container {
  margin-top: 150px;
}
@media (max-width: 768px) {
  section#container #left.page404 .error-404.not-found {
    width: 100%;
  }
}
section#container #left.page404:before {
  display: none;
}
section#container #left.corporateTemplate {
  background-size: cover;
}
section#container #left.corporateTemplate .contentBox {
  background: transparent;
}
section#container #left.corporateTemplate .contentBox .textWrap {
  display: block;
  width: 50vw;
}
section#container #left .sidebar {
  right: 10vw;
}
section#container #left .sidebar ul {
  width: 100%;
}
section#container #left .sidebar ul li {
  text-align: right;
  width: 100%;
  padding: 7px 0;
  color: #ffffff;
}
section#container #left .sidebar ul li a {
  text-transform: uppercase;
  color: #ffffff;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.3em;
}
section#container #left .sidebar ul li a:hover {
  text-decoration: underline;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-out;
}
section#container #left .sidebar ul li.current-menu-item {
  position: relative;
}
section#container #left .sidebar ul li.current-menu-item:after {
  position: absolute;
  top: 2px;
  content: '';
  right: -45px;
  background-image: url('../images/ico-current-menu.png');
  background-size: 100% auto;
  width: 32px;
  height: 32px;
}
section#container #left .sidebar ul li.current-menu-item a {
  text-decoration: underline;
}
section#container #left .fixedContent {
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: hidden;
  width: 100%;
  z-index: 4;
}
section#container #left .fixedContent:before {
  position: absolute;
  content: '';
  top: -1.25vh;
  height: 116.5vh;
  right: -150%;
  background-image: url('../images/bg-overlay.png');
  background-size: 100% auto;
  width: 300%;
  z-index: -1;
}
section#container #left .fixedContent .contentBox {
  z-index: 4;
}
section#container #left.noBg {
  background: red;
  border: 6px solid red;
}
section#container #right {
  position: absolute;
  overflow: hidden;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-out;
  background-position: right center;
}
@media (max-width: 767px) {
  section#container #right {
    height: auto;
    padding: 50px 0;
  }
}
@media (max-width: 767px) {
  section#container #right .right-static {
    position: static;
    transform: none;
  }
}
section#container #right:after {
  position: absolute;
  z-index: 2;
  content: '';
  top: -1.25vh;
  right: 0;
  width: 100vw;
  height: 116.5vh;
  background-image: url('../images/bg-home.jpg');
  background-position: 0vw -14vh;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 767px) {
  section#container #right:after {
    display: none;
  }
}
section#container #right.noBgImage:after {
  display: none;
}
section#container #right .bg_right_overlay {
  position: absolute;
  display: none;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  z-index: 3;
}
section#container #right .bg_right_overlay.section_glor {
  background-image: url('../images/bg_glor.jpg');
  background-position: right center;
  background-size: auto 100%;
}
section#container #right .bg_right_overlay.section_glor:after,
section#container #right .bg_right_overlay.section_glor:before {
  display: none;
}
section#container #right .bg_right_overlay.section_prosecco {
  background-image: url('../images/bg_prosecco.jpg');
  background-position: right center;
  background-size: auto 100%;
}
section#container #right .bg_right_overlay.section_prosecco:after,
section#container #right .bg_right_overlay.section_prosecco:before {
  display: none;
}
section#container #right .bg_right_overlay.section_spumante {
  background-image: url('../images/bg_spumante.jpg');
  background-position: right center;
  background-size: auto 100%;
}
section#container #right .bg_right_overlay.section_spumante:after,
section#container #right .bg_right_overlay.section_spumante:before {
  display: none;
}
section#container #right .row33 {
  position: relative;
  float: right;
  clear: both;
  /*height: 33.33vh;*/
  height: 25vh;
  width: 31vw;
  z-index: 4;
}
@media (max-width: 767px) {
  section#container #right .row33 {
    height: 100vh;
    width: 100%;
  }
}
section#container #right .row33:nth-child(1) {
  margin-top: 8vh;
}
@media (max-width: 767px) {
  section#container #right .row33:nth-child(1) {
    background-image: url('../images/bg_glor.jpg');
    background-position: right center;
    background-size: cover;
    margin-top: -50px;
  }
}
@media (max-width: 767px) {
  section#container #right .row33:nth-child(2) {
    background-image: url('../images/bg_prosecco.jpg');
    background-position: right center;
    background-size: cover;
  }
}
@media (max-width: 767px) {
  section#container #right .row33:nth-child(3) {
    background-image: url('../images/bg_spumante.jpg');
    background-position: right center;
    background-size: cover;
    margin-bottom: -50px;
  }
}
section#container #right .row33 .cta {
  text-align: center;
  left: 50%;
  min-width: 202px;
  width: 202px;
  border: 3px solid #ffffff;
  padding: 22px 0;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 20.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media (max-width: 767px) {
  section#container #right .row33 .cta {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.4);
    letter-spacing: 0.6em;
  }
}
@media (max-width: 1440px) {
  section#container #right .row33 .cta {
    min-width: 202px;
    letter-spacing: 0.4em;
  }
}
section#container #right .row33 .cta:hover {
  background: #000000;
  text-shadow: 0 0 50px #ffffff, 0 0 5px #ffffff;
  border: 3px solid #333333;
}
@media (max-width: 768px) {
  section#container #right .row33 .cta {
    width: 150px;
    min-width: 150px;
  }
}
section#container #right.bg-color:after,
section#container #right.bg-color:before {
  display: none;
}
section#container .contentDetails {
  width: 90%;
  margin: 0 auto;
}
@media (min-width: 768px) {
  section#container .contentDetails {
    width: 29vw;
    right: 5vw;
    margin: 0;
  }
}
section#container .contentDetails h2 {
  font-weight: 300;
  font-size: 17px;
  line-height: 23px;
  letter-spacing: 0.03em;
  font-style: normal;
  margin: 0;
}
section#container .contentDetails h2:last-of-type {
  margin-bottom: 20px;
}
section#container .contentDetails h3 {
  position: relative;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 0.3em;
  font-weight: 600;
  margin-bottom: 25px;
  color: #ffffff;
}
section#container .contentDetails p {
  font-size: 17px;
  line-height: 23px;
  letter-spacing: 0.03em;
  font-style: normal;
}
section#container .contentDetails a {
  color: #ffffff;
}
section#container .contentDetails .accordion {
  width: 100%;
}
section#container .contentDetails .accordion .rowAccordion {
  width: 100%;
}
section#container .contentDetails .accordion .rowAccordion h2,
section#container .contentDetails .accordion .rowAccordion h3 {
  color: #ffffff;
  cursor: pointer;
}
section#container .contentDetails .accordion .rowAccordion h2:after,
section#container .contentDetails .accordion .rowAccordion h3:after {
  position: absolute;
  right: 0;
  content: '';
  cursor: pointer;
  width: 12px;
  height: 12px;
  background-image: url('../images/ico-more.png');
  background-size: 100% auto;
}
section#container .contentDetails .accordion .rowAccordion h2.close:after,
section#container .contentDetails .accordion .rowAccordion h3.close:after {
  content: '';
  cursor: pointer;
  width: 12px;
  height: 12px;
  background-image: url('../images/ico-minus.png');
  background-size: 100% auto;
}
section#container .contentDetails .accordion .rowAccordion h2 {
  font-weight: 500;
  text-transform: uppercase;
  position: relative;
}
section#container .contentDetails .accordion .rowAccordion .text {
  height: 0;
  overflow: hidden;
  padding-right: 50px;
}
section#container .contentDetails .wpcf7 {
  display: inline-block;
  *display: inline;
  zoom: 1;
  width: 100%;
}
section#container .contentDetails .wpcf7 .row {
  display: inline-block;
  *display: inline;
  zoom: 1;
  width: 100%;
  margin-bottom: 15px;
  font-size: 17px;
  line-height: 23px;
  letter-spacing: 0.03em;
  font-style: 500;
  color: #ffffff;
}
section#container .contentDetails .wpcf7 .row input {
  width: 100%;
  padding: 15px 20px;
  font-size: 16px;
  border: 0;
}
section#container .contentDetails .wpcf7 .row input[type='checkbox'] {
  float: left;
  width: 15px;
  height: 15px;
  margin: 0;
  padding: 0;
  margin-top: 0px;
}
section#container .contentDetails .wpcf7 .row input[type='submit'] {
  background: #000000;
  width: 200px;
  margin-left: calc((100% - 200px) / 2);
  color: #ffffff;
}
section#container .contentDetails .wpcf7 .row textarea {
  width: 100%;
  padding: 10px 20px;
  font-size: 16px;
  border: 0;
}
section#container .listing {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  top: 0;
  bottom: 0;
  background-color: #ffffff;
  width: auto;
  z-index: 30;
  margin-left: 58vw;
  padding: 0 50px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  section#container .listing {
    width: 100%;
    padding-top: 8%;
  }
}
@media (max-width: 767px) {
  section#container .listing {
    margin-left: 100vw;
  }
}
section#container .scrollBottom {
  display: none;
}
@media (max-width: 767px) {
  section#container .scrollBottom {
    display: block;
    width: 100%;
    position: absolute;
    bottom: 0px;
    text-align: center;
    z-index: 9;
    height: 40px;
  }
}
section#container .listing-arrow--forward {
  /*.pa;*/
  position: fixed;
  bottom: 40px;
  right: 50px;
  display: block;
  z-index: 100;
  cursor: pointer;
}
section#container .listing-arrow--forward.home-arrow {
  display: inline-block;
  *display: inline;
  zoom: 1;
  position: relative;
  transform: rotate(90deg);
  left: auto;
  right: auto;
  bottom: auto;
  pointer-events: none;
}
section#container .listing-arrow--forward.home-arrow.home-arrow-spumante {
  display: none;
}
@media (min-width: 768px) {
  section#container .listing-arrow--forward {
    position: fixed;
  }
}
section#container .listingItem {
  text-align: center;
  width: 100vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  0-webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
@media (min-width: 768px) {
  section#container .listingItem {
    width: 50vw;
  }
}
@media (min-width: 1024px) {
  section#container .listingItem {
    width: 25vw;
  }
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  section#container .listingItem {
    width: 50vw;
  }
}
section#container .listingItem a {
  position: relative;
  text-align: center;
}
section#container .listingItem a .listingImg {
  position: relative;
  margin: 0 auto;
  width: 60%;
}
@media (max-width: 767px) {
  section#container .listingItem a .listingImg {
    width: 45%;
  }
}
section#container .listingItem a h2 {
  opacity: 0;
  visibility: hidden;
  height: 0;
  margin: 0;
  bottom: 0;
  font-size: 12px;
  line-height: 17.5px;
  letter-spacing: 0.3em;
  color: #000000;
  text-transform: uppercase;
  width: 75%;
  text-align: center;
  margin: 0 auto;
  transition: all 0.35s ease-in-out;
}
@media (max-width: 1024px) {
  section#container .listingItem a h2 {
    opacity: 1;
    visibility: visible;
  }
}
section#container .listingItem a .bgOverlay {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 0.35s ease-in-out;
  left: 50%;
  transform: translateX(-50%);
  width: 70%;
  margin: auto;
}
@media (max-width: 767px) {
  section#container .listingItem a .bgOverlay {
    width: 60%;
  }
}
@media (max-width: 1024px) {
  section#container .listingItem a .bgOverlay {
    opacity: 1;
    visibility: visible;
    transition: none;
  }
}
section#container .listingItem a:hover h2 {
  opacity: 1;
  visibility: visible;
}
section#container .listingItem a:hover .bgOverlay {
  opacity: 1;
  visibility: visible;
}
section#container #stopScroll {
  width: 1px;
  height: 1px;
}
section #multiscroll {
  padding-top: 100px;
}
section #multiscroll .close_list {
  position: relative;
  background: url('../images/ico-close.png') center center no-repeat;
  width: 25px;
  height: 25px;
  display: none;
  background-size: 100%;
  margin: 0 auto;
}
@media (max-width: 767px) {
  section #multiscroll .close_list {
    display: block;
    margin: 20px 0;
  }
}
@media (max-width: 767px) {
  section #multiscroll {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    0-webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
section .ms-section:not(.active) {
  display: none;
}
@media (min-width: 768px) {
  section .ms-section:not(.active) {
    display: block;
  }
}
@media (max-width: 767px) {
  section .ms-left {
    order: 2;
  }
}
@media (max-width: 767px) {
  section .ms-right {
    order: 1;
  }
}
section .ms-left,
section .ms-right {
  touch-action: auto !important;
}
@media (max-width: 767px) {
  section .ms-left,
  section .ms-right {
    width: 100% !important;
    position: static !important;
    height: auto !important;
  }
}
section .temporary {
  position: absolute;
  width: 100%;
  top: 0;
}
@media (max-width: 768px) {
  section .ms-tableCell {
    height: auto !important;
  }
}
section .wine-detail-body {
  font-weight: 300;
  overflow-y: auto !important;
}
@media (min-width: 768px) {
  section .wine-detail-body {
    overflow-y: hidden !important;
  }
}
@media (min-width: 1024px) {
  section .right-section {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
section .right-section,
section .left-section {
  float: left;
  position: relative;
  height: 100vh;
  font-size: 1.125em;
  width: 100%;
}
section .right-section .download-link,
section .left-section .download-link {
  color: #dcaf42;
  text-transform: uppercase;
  font-weight: 500;
  display: block;
  font-size: 20px;
  z-index: 30;
}
section .right-section .download-section.download-desktop,
section .left-section .download-section.download-desktop {
  display: none;
}
@media (min-width: 768px) {
  section .right-section .download-section.download-desktop,
  section .left-section .download-section.download-desktop {
    display: block;
    float: left;
    position: relative;
    width: 10vw;
    height: 100vh;
    z-index: 10;
  }
}
section .right-section .download-section.download-desktop .download-link,
section .left-section .download-section.download-desktop .download-link {
  transform: rotate(0);
  position: relative !important;
}
@media (min-width: 768px) {
  section .right-section .download-section.download-desktop .download-link,
  section .left-section .download-section.download-desktop .download-link {
    font-size: 13px;
    transform: rotate(-90deg);
    position: relative !important;
    width: 200%;
    left: -50%;
  }
}
section .right-section .download-section.download-mobile,
section .left-section .download-section.download-mobile {
  text-align: center;
  display: block;
}
@media (min-width: 768px) {
  section .right-section .download-section.download-mobile,
  section .left-section .download-section.download-mobile {
    display: none;
  }
}
section .right-section .wine-detail,
section .left-section .wine-detail {
  float: left;
  color: #333333;
  padding-top: 30%;
}
@media (min-width: 768px) {
  section .right-section .wine-detail,
  section .left-section .wine-detail {
    padding-left: 10vw;
    padding-top: 0;
  }
}
section .right-section .wine-detail h1,
section .left-section .wine-detail h1 {
  width: 90%;
  margin: 0 auto 10px;
  font-size: 22px;
  text-transform: none;
  font-weight: 600;
  color: #333333;
  letter-spacing: 0.03em;
  line-height: 1.14em;
}
@media (min-width: 768px) {
  section .right-section .wine-detail h1,
  section .left-section .wine-detail h1 {
    margin: 0 0 30px;
    font-size: 35px;
  }
}
section .right-section .wine-detail p,
section .left-section .wine-detail p {
  color: #333333;
}
@media (max-width: 767px) {
  section .right-section .wine-detail,
  section .left-section .wine-detail {
    padding-top: 35px;
  }
}
@media (max-width: 767px) {
  section .right-section .wine-detail.vAlign,
  section .left-section .wine-detail.vAlign {
    position: static;
    top: auto;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}
section .right-section.textCenter,
section .left-section.textCenter {
  text-align: center;
}
section .right-section.textCenter .wine-detail__bg,
section .left-section.textCenter .wine-detail__bg {
  position: fixed;
  top: 20%;
  bottom: 0;
  right: -5%;
  z-index: -1;
  width: 50%;
  height: auto;
}
@media (max-width: 767px) {
  section .right-section.textCenter .wine-detail__bg,
  section .left-section.textCenter .wine-detail__bg {
    display: none;
    /* FIX MOMENTANEO */
    position: absolute;
    left: 10%;
    right: auto;
    z-index: -1;
    height: 100%;
    width: auto;
  }
}
section .right-section.textCenter .wine-detail__img,
section .left-section.textCenter .wine-detail__img {
  position: relative;
  margin-top: 0px;
  height: 60vh;
  width: auto;
  z-index: 3;
}
@media (max-width: 767px) {
  section .right-section {
    position: static;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}
.fixed-btn {
  display: none;
}
@media (min-width: 768px) {
  .fixed-btn {
    display: block;
    z-index: 10;
    position: fixed;
    color: #ffffff;
  }
}
.direction-btn {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  font-size: 0;
  text-indent: -999px;
  background-repeat: no-repeat;
  width: 10px;
  height: 38px;
  background-size: 100% auto;
  cursor: pointer;
  z-index: 999;
}
.prev-btn {
  background-image: url(../images/top-arrow.png);
  top: 20px;
}
.next-btn {
  background-image: url(../images/bottom-arrow.png);
  bottom: 20px;
}
section#container .wine-detail .contentDetails .accordion {
  width: 100%;
}
section#container .wine-detail .contentDetails .accordion .rowAccordion h2,
section#container .wine-detail .contentDetails .accordion .rowAccordion h3 {
  width: 50%;
  line-height: 1;
  margin: 0 0 17px;
  color: #333333;
}
section#container .wine-detail .contentDetails .accordion .rowAccordion h2:after,
section#container .wine-detail .contentDetails .accordion .rowAccordion h3:after {
  background-image: url('../images/ico-more-black.png');
  background-size: 100% auto;
  width: 12px;
  height: 12px;
}
section#container .wine-detail .contentDetails .accordion .rowAccordion h2.close:after,
section#container .wine-detail .contentDetails .accordion .rowAccordion h3.close:after {
  background-image: url('../images/ico-minus-black.png');
  background-size: 100% auto;
  height: 5px;
  width: 12px;
}
@media (max-width: 1400px) {
  section#container .wine-detail .contentDetails .accordion .rowAccordion h2,
  section#container .wine-detail .contentDetails .accordion .rowAccordion h3 {
    width: 100%;
  }
}
section#container .wine-detail .contentDetails .accordion.oro .rowAccordion {
  width: 100%;
}
section#container .wine-detail .contentDetails .accordion.oro .rowAccordion h2,
section#container .wine-detail .contentDetails .accordion.oro .rowAccordion h3 {
  color: #dcaf42 !important;
  margin-bottom: 17px;
}
section#container .wine-detail .contentDetails .accordion.oro .rowAccordion h2:after,
section#container .wine-detail .contentDetails .accordion.oro .rowAccordion h3:after {
  background-image: url('../images/ico-more-gold.png');
  background-size: 100% auto;
  width: 12px;
  height: 12px;
}
section#container .wine-detail .contentDetails .accordion.oro .rowAccordion h2.close:after,
section#container .wine-detail .contentDetails .accordion.oro .rowAccordion h3.close:after {
  background-image: url('../images/ico-minus-gold.png');
  background-size: 100% auto;
  height: 5px;
  width: 12px;
}
section#container.frontPage {
  background-image: url('../images/bg-home.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}
section#container.frontPage #right:before {
  position: absolute;
  z-index: 3;
  content: '';
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}
@media (max-width: 767px) {
  section#container.frontPage #right:before {
    display: none;
  }
}
span.wpcf7-list-item {
  margin: 0 !important;
}
.glor_normal_text p {
  font-style: normal;
}
.banner {
  position: fixed;
  text-align: center;
  left: 0;
  bottom: 0;
  font-size: 0;
  width: 100vw;
  z-index: 1000;
}
.banner img {
  display: inline-block;
  *display: inline;
  zoom: 1;
  max-width: 100%;
}
.banner .close {
  position: absolute;
  top: 0;
  z-index: 2000;
  background: url('../images/ico-close.png') center center no-repeat;
  background-size: 15px 15px;
  width: 25px;
  height: 25px;
  right: 5px;
  background-color: white;
  border-radius: 100%;
  cursor: pointer;
}
@media (max-width: 768px) {
  .banner.bannerDesktop {
    display: none;
  }
}
@media (min-width: 1390px) {
  .banner.bannerDesktop .close {
    right: calc((100vw - 1390px) / 2);
  }
}
.banner.bannerMobile {
  display: none;
}
.banner.bannerMobile img {
  width: 100%;
}
.banner.bannerMobile .close {
  right: 5px;
}
@media (max-width: 768px) {
  .banner.bannerMobile {
    display: block;
  }
}
@keyframes pulse_effect {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  8% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  15% {
    -webkit-transform: scale(0.1);
    opacity: 1;
  }
  30% {
    -webkit-transform: scale(0.5);
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
  }
}
@-webkit-keyframes pulse_effect {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  8% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  15% {
    -webkit-transform: scale(0.1);
    opacity: 1;
  }
  30% {
    -webkit-transform: scale(0.5);
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes pulse_effect {
  0% {
    -moz-transform: scale(0);
    opacity: 0;
  }
  8% {
    -moz-transform: scale(0);
    opacity: 0;
  }
  15% {
    -moz-transform: scale(0.1);
    opacity: 1;
  }
  30% {
    -moz-transform: scale(0.5);
    opacity: 1;
  }
  100% {
    opacity: 0;
    -moz-transform: scale(1);
  }
}
/* ==========================================================================
   Media Queries
	 ========================================================================== */
@media (max-width: 1024px) {
  header .menuOverlay .menuClose {
    left: 20px;
    top: 50px;
  }
  header .menuOverlay [class^='menu-main-'] {
    width: 100vw;
    height: 100%;
    box-sizing: border-box;
    overflow-y: scroll;
    display: flex;
    align-items: center;
  }
  header .menuOverlay [class^='menu-main-'] .menu {
    padding-left: 0;
    width: 100%;
  }
  header .menuOverlay [class^='menu-main-'] .menu li {
    text-align: center;
    padding: 35px 0;
  }
  header .menuOverlay [class^='menu-main-'] .menu li a {
    font-size: 25px;
  }
  header .menuOverlay [class^='menu-main-'] .menu .sub-menu {
    display: none;
    background-image: none !important;
    background-color: #000 !important;
    position: static;
    padding: 0px;
    right: auto;
    left: 0px;
    width: auto;
    height: auto;
  }
  header .menuOverlay [class^='menu-main-'] .menu .sub-menu li {
    padding: 10px 0 15px 0;
  }
  header .menuOverlay [class^='menu-main-'] .menu .sub-menu li a:after {
    position: absolute;
    top: 5px;
    content: '';
    left: -52px;
    background-image: url(../images/ico-current-menu.png);
    background-size: 100% auto;
    width: 30px;
    height: 30px;
  }
  header .menuOverlay .bg_menu {
    background-image: none;
    background-color: #000;
  }
  header .menuOverlay .link-social {
    bottom: 100px;
    width: 60vw;
    padding: 0;
  }
  header .menuOverlay .link-footer {
    bottom: 100px;
    width: 40vw;
    text-align: right;
    padding: 0;
  }
  header .menuOverlay .credits {
    padding: 0 0 15px 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    width: 82%;
    text-align: center;
    margin: 0 9%;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  header .menuOverlay [class^='menu-main-'] .menu li {
    padding: 30px 15px 30px 60px;
  }
  header .menuOverlay .bg_menu {
    background-image: none;
    background-color: #000;
  }
  header .menuOverlay .link-social {
    padding-left: 130px;
  }
  header .menuOverlay .link-footer {
    margin: 0 10px;
  }
}
@media (max-width: 767px) {
  header .menuOverlay [class^='menu-main-'] .menu li {
    text-align: center;
    padding: 20px 0;
  }
  header .menuOverlay [class^='menu-main-'] .menu li a {
    font-size: 22px;
  }
}
@media (max-width: 1024px) {
  header .menuOverlay [class^='menu-main-'] .menu li {
    text-align: center;
    padding: 20px 0;
  }
  header .menuOverlay [class^='menu-main-'] .menu li a {
    font-size: 22px;
  }
  header .menuOverlay [class^='menu-main-'] .menu li .sub-menu,
  header .menuOverlay [class^='menu-main-'] .menu li .sub-menu * {
    pointer-events: none;
  }
  header .menuOverlay [class^='menu-main-'] .menu li .contentMenu {
    position: fixed;
    width: 100vw;
    /*height: 100vh;*/
    background: #000000;
    min-height: 100%;
    top: 0;
    z-index: 1000;
  }
  header .menuOverlay [class^='menu-main-'] .menu li .contentMenu .menuVisible {
    display: block;
    padding-left: 0;
    padding-top: 0vh;
    position: absolute;
    width: 100%;
    transform: translateY(-50%);
    top: 50%;
  }
  header .menuOverlay [class^='menu-main-'] .menu li .contentMenu .sub-menu,
  header .menuOverlay [class^='menu-main-'] .menu li .contentMenu .sub-menu * {
    pointer-events: all;
  }
  header .menuOverlay .submenuClose {
    position: absolute;
    width: 21px;
    height: 21px;
    background: #000000;
    z-index: 1000;
    left: 20px;
    top: 50px;
    background-image: url(../images/submenu-back.png);
  }
}
.new-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  text-align: center;
  display: none;
  width: 100%;
  z-index: 9;
}
.new-banner img {
  max-width: 1366px;
  width: 100%;
  cursor: pointer;
  position: relative;
}
@media (max-width: 1024px) {
  .new-banner .banner-desktop {
    display: none;
  }
}
@media (min-width: 1024px) {
  .new-banner .banner-mobile {
    display: none;
  }
}
@media (max-width: 766px) {
  .banner-unesco-img {
    bottom: 0 !important;
    max-width: 90% !important;
  }
  .banner-unesco-img img {
    bottom: 70px !important;
    top: auto !important;
  }
}
.eyelet * {
  margin: 0;
  font-size: 16px;
  line-height: 23px;
  font-style: normal;
  font-weight: 500;
}
.premi_riconoscimenti {
  margin-bottom: 40px;
}
.premi_riconoscimenti__header--image-container {
  min-height: 500px;
  width: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}
.premi_riconoscimenti__header--title {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 40%;
  font-size: 32px;
  line-height: 40px;
  font-weight: 700!important;
  display: flex;
  padding: 0 60px;
  margin: 0;
  color: #fff;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.premi_riconoscimenti__header--title p {
  margin: 0 !important;
}
.premi_riconoscimenti__header--description {
  font-family: 'Playfair Display', Georgia, Times, "Times New Roman", serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  width: auto;
  display: inline-block;
  padding: 0px 60px;
  margin: 0;
  color: #fff;
}
.premi_riconoscimenti__body {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1200px;
  max-height: 440px;
  overflow-y: scroll;
  padding: 20px;
  margin: auto;
}
.premi_riconoscimenti__body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  background-color: #F5F5F5;
}
.premi_riconoscimenti__body::-webkit-scrollbar {
  width: 5px;
  background-color: #F5F5F5;
}
.premi_riconoscimenti__body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #daae47;
}
.premi_riconoscimenti__body--single {
  font-family: PT Sans, sans-serif;
  font-size: 30px;
  line-height: 37px;
  font-weight: 600!important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: calc(50% - 20px);
  min-height: 120px;
  margin-top: 10px;
  background-color: transparent;
  border: 2px solid #F1DFB7;
  border-radius: 10px;
  background-size: 140px;
  background-repeat: no-repeat;
  background-position: 50%;
  cursor: pointer;
  color: #fff;
}
.premi_riconoscimenti__body--single:hover .premi_riconoscimenti__body--single-count {
  opacity: 1;
  display: block;
}
.premi_riconoscimenti__body--single:hover .premi_riconoscimenti__body--single-title {
  opacity: 0;
  display: none;
}
.premi_riconoscimenti__body--single .premi_riconoscimenti__body--single-count {
  display: none;
  margin: 0;
  opacity: 0;
}
.premi_riconoscimenti__body--single .premi_riconoscimenti__body--single-title {
  display: block;
  margin: 0;
  opacity: 1;
}
@media (max-width: 780px) {
  .premi_riconoscimenti__body--single {
    width: calc(100% - 40px);
  }
}
.premi_riconoscimenti__body.ajaxify {
  display: none;
}
.premi_riconoscimenti__ajax-container {
  display: none;
  max-width: 1200px;
  padding: 20px;
  margin: auto;
}
.premi_riconoscimenti__ajax-container.ajaxify {
  display: flex;
  flex-direction: column;
}
.premi_riconoscimenti__ajax-container--back {
  font-family: PT Sans, sans-serif;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 1.2px;
  font-weight: 600 !important;
  display: block;
  margin: 20px 0;
  cursor: pointer;
  color: #fff;
}
.premi_riconoscimenti__ajax-container--slider {
  position: relative;
  display: block;
  width: 100%;
  max-width: 350px;
  margin: auto;
  padding-top: 0 !important;
  background-color: transparent !important;
  background-size: 200px;
  background-repeat: no-repeat;
  background-position: center;
}
.premi_riconoscimenti__ajax-container--slider-container {
  display: block;
  width: 100%;
  background-color: transparent !important;
}
.premi_riconoscimenti__ajax-container--slider .slick-next {
  position: absolute;
  top: 0px;
  z-index: 9999;
  bottom: 0;
  right: 0;
  margin: auto;
  border: none;
  color: transparent;
  background: transparent url(../img/next-black.png) 50% no-repeat !important;
  background-size: 40px !important;
  width: 40px;
  height: 40px;
}
.premi_riconoscimenti__ajax-container--slider .slick-next:before {
  display: none;
}
.premi_riconoscimenti__ajax-container--slider .slick-prev {
  position: absolute;
  top: 0px;
  z-index: 9999;
  left: 0;
  bottom: 0;
  margin: auto;
  border: none;
  color: transparent;
  background: transparent url(../img/prev-black.png) 50% no-repeat !important;
  background-size: 40px !important;
  width: 40px;
  height: 40px;
}
.premi_riconoscimenti__ajax-container--slider .slick-prev:before {
  display: none;
}
.premi_riconoscimenti__ajax-container--single {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px !important;
  background-color: transparent;
  font-size: 30px;
  line-height: 37px;
  font-weight: 600 !important;
  color: #fff;
}
.premi_riconoscimenti__ajax-container-items {
  max-width: 600px;
  max-height: 350px;
  overflow-y: scroll;
  margin: 40px auto;
  padding: 20px;
}
.premi_riconoscimenti__ajax-container-items::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  background-color: #F5F5F5;
}
.premi_riconoscimenti__ajax-container-items::-webkit-scrollbar {
  width: 5px;
  background-color: #F5F5F5;
}
.premi_riconoscimenti__ajax-container-items::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #daae47;
}
.premi_riconoscimenti__ajax-container-items--single {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-family: PT Sans, sans-serif;
  font-size: 20px;
  line-height: 24px;
  margin: 20px 0;
  color: #000 !important;
}
.premi_riconoscimenti__ajax-container-items--single img {
  width: 40px;
  margin-right: 10px;
}
.premi_riconoscimenti__lds-dual-ring {
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.8);
}
.premi_riconoscimenti__lds-dual-ring.active {
  display: flex;
}
.premi_riconoscimenti__lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #a07e27;
  border-color: #a07e27 transparent #a07e27 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
